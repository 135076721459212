import { Component, OnInit } from '@angular/core';
import { Hero } from '../hero';
import { HeroService } from '../hero.service';

//import { Elastic, TimelineLite, TweenLite } from "gsap";
//import { HEROES } from '../mock-heroes';

@Component({
  selector: 'app-heroes',
  templateUrl: './heroes.component.html',
  styleUrls: ['./heroes.component.sass']
})
export class HeroesComponent implements OnInit {
  heroes: Hero[];
    selectedHero: Hero;

  constructor(private heroService: HeroService) {
  
  }

  ngOnInit() {
    this.getHeroes();
  }

  getHeroes(): void {
    this.heroService.getHeroes()
    .subscribe(heroes => this.heroes = heroes);
  }

    
    
    toggleChild($event) {
        /**
        let xx = $event;
       xx.addPause("start+=2", ()=>{
           console.log("lala"); 
           xx.reverse();
       });
       **/
    }
}