import { Component, OnInit, ElementRef, ViewChild, ViewChildren, HostListener } from '@angular/core';

import { Router, ActivatedRoute, PRIMARY_OUTLET, NavigationEnd } from '@angular/router';

import { Elastic, TimelineLite, TweenLite, Power2, Linear, ScrollToPlugin } from "gsap/all";


import { ScrollTopService } from '../scroll-top.service';


import { Observable,  pipe } from 'rxjs';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-project-box',
  templateUrl: './project-box.component.html',
  styleUrls: ['./project-box.component.sass'],
    providers: [ScrollTopService]
})
export class ProjectBoxComponent implements OnInit {

    public showFilter:boolean;
    public submenutype:Number;

    private get elem(): HTMLElement {
      return this._elem.nativeElement;
    }
    
    /**
    testing(){
        
  
   // r={ init:function(e,t){
            var o=e.view,a=e.req,r="servicesController"===a.prevCtrl;
            a.prevCtrl;e.navbarNavItems.filter('[href="/services/"]').addClass("-active");
            var i=o.find(".cb-projectbox-menu"),s=i.find(".cb-projectbox-menu-nav");
            var l=s.find(".cb-projectbox-menu-nav-inner");
            var c=l.find(".cb-projectbox-menu-nav-item");
            var f=c.filter(".-active"),d=i.find(".cb-projectbox-menu-filter-item");
            
            e.mobile||c.on("click",function(t){
                t.preventDefault(),t.stopPropagation();
                var o=$(this),a=o.attr("href");
                if(o.hasClass("-active"))
                    return false;
                
                l.animate({scrollLeft:.4*o.offset().left})
                c.removeClass("-active");
                o.addClass("-active");
                e.goTo({url:a});
            }),
                
            d.on("click",function(e){
                e.preventDefault(),e.stopPropagation();
                var t=$(this),a=t.index(),n=o.find(".cb-projectbox-area").eq(a);
                var r=parseInt(o.find(".cb-projectbox-wrap").css("padding-top"))+(n.hasClass("aos-animate")?0:100);
                TweenLite.to(window,1,{scrollTo:{y:n,offsetY:r},ease:Power2.easeInOut,autoKill:!1})
            });
            //var u=function(){
           //     var e=o.find(".cb-projectbox-area"),t=parseInt(o.find(".cb-projectbox-wrap").css("padding-top"))+100,a=window.pageYOffset+t,n=null;d.removeClass("-active");
    
      }
    **/
    
  constructor(
      private scrollTopService: ScrollTopService,
      private _elem: ElementRef,
      private router: Router, 
      private route: ActivatedRoute) { }
    
    private windowScrollLeft: any;
    private windowScrollTop: any;
    
     @ViewChild("projectboxbody")
	private _projectboxbody: ElementRef;
	private get projectBoxBody(): HTMLElement {
		return this._projectboxbody.nativeElement as HTMLElement;
	}
    
        
     @ViewChild("projectbox")
	private _projectbox: ElementRef;
	private get projectBox(): HTMLElement {
		return this._projectbox.nativeElement as HTMLElement;
	}
    
    
    
    
    
   sublist: Array<Array<{url: string, name: string}>> = [
       [
            {url: 'sdwan', name: "Tungshin Hospital Website"},
            {url: 'aci', name: "DMX Packets Website"}
        ],
       [
           {url: 'esosapp', name: "Dialog ESOS App"},
           {url: 'destroy', name: "Marketing Game"}
        ],
       [
           {url: 'esosportal', name: "Dialog ESOS Portal"}
        ],
    ];
    

                  
    
    /**
    @ViewChild("projectimg")
	private _projectImg: ElementRef;
   
     
	private get projectImg(): HTMLDivElement {
		return this._projectImg.nativeElement as HTMLDivElement;
	}
     
    @HostListener('window:scroll', ['$event']) 
    doSomething(event) {
       console.log("clientX="+event.clientX);
       this.windowScrollLeft = window.scrollX;
       this.windowScrollTop = window.scrollY;
        
      //console.debug("this.windowScrollLeft=", this.windowScrollLeft );
      //console.debug("this.windowScrollTop=", this.windowScrollTop );
    }



         /**
    @HostListener('mousemove', ['$event'])
    onMousemove(event: MouseEvent) {
        var t=this.projectImg;
       
        
        
        var a=t.offsetWidth;
        var n=t.offsetHeight;
        var r=t.offsetLeft-this.windowScrollLeft;
        var i=t.offsetTop-this.windowScrollTop;
        var s=(o.clientX-r)/a;
        var l=(o.clientY-i)/n;
        var c=2*(l-.5);
        var f=(5-10*s).toFixed(2);
        var d=((10*l-5).toFixed(2),20*c);
        TweenLite.to(t,.3,{scale:1.07,rotationY:f,y:d})
            
    }
**/


    /**
    t.find(".cb-project-img").each(function(){
        var t=$(this);
        
        t.on("mousemove",function(o){
            var a=t.outerWidth();
            var n=t.outerHeight();
            var r=t.offset().left-e.window.scrollLeft();
            var i=t.offset().top-e.window.scrollTop();
            var s=(o.clientX-r)/a;
            var l=(o.clientY-i)/n;
            var c=2*(l-.5);
            var f=(5-10*s).toFixed(2);
            var d=((10*l-5).toFixed(2),20*c);
            TweenLite.to(t,.3,{scale:1.07,rotationY:f,y:d})
        });
        
        t.on("mouseleave",function(e){
            TweenLite.to(this,.4,{scale:1,rotationX:0,rotationY:0,y:0})
        });
    });
    **/
    
    
    
    

  ngOnInit() {
      
      this.showFilter = false;
      this.submenutype = 1;
     
      
      this.scrollTopService.setScrollTop();
      //console.log(window);
     this.routeChanged(true);
      /**
        var linkElem = event.path[1];
        var linkParentElem = event.path[2];

        if(linkElem.classList.contains("-active"))
            return false;

        
        for (let child of linkParentElem.children) {
            if(child.classList.contains("-active")){
                child.classList.remove("-active");
            }
        }
      **/
      
      
      
      
      
          //data-link-url
//var passed = [12, 5, 8, 130, 44].filter((element, index, array) => {return (element >= 10)}); 
//console.log("Test Value : " + passed );
      
      
      /**
      console.log(this.router.url);
      
        const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
        const s: UrlSegment[] = g.segments;
        s[0].path; // returns 'team'
        s[0].parameters; // returns {id: 33}
**/

      
      /**
        e.navbarNavItems.filter('[href="/services/"]').addClass("-active");

        var i=o.find(".cb-projectbox-menu");
        var s=i.find(".cb-projectbox-menu-nav");
        var l=s.find(".cb-projectbox-menu-nav-inner");
        var c=l.find(".cb-projectbox-menu-nav-item");
        var f=c.filter(".-active");
        var d=i.find(".cb-projectbox-menu-filter-item");

        e.mobile||c.on("click",function(t){
            t.preventDefault(),t.stopPropagation();
            var o=$(this); 
            var a=o.attr("href");
            if(o.hasClass("-active"))
                return false;

        l.animate({scrollLeft:.4*o.offset().left});
        c.removeClass("-active");
        o.addClass("-active");
            e.goTo({url:a})
        });
      
      

        ,d.on("click",function(e){e.preventDefault(),e.stopPropagation();var t=$(this),a=t.index(),n=o.find(".cb-projectbox-area").eq(a),r=parseInt(o.find(".cb-projectbox-wrap").css("padding-top"))+(n.hasClass("aos-animate")?0:100);TweenLite.to(window,1,{scrollTo:{y:n,offsetY:r},ease:Power2.easeInOut,autoKill:!1})});
**/
      
      
      
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(
            x => { 
                    this.routeChanged(false);
                 }
        );
      
      
  }
    
    
    
    
    public routeChanged(isFreshLoad){
        var tree = this.router.parseUrl(this.router.url);

        var g = tree.root.children[PRIMARY_OUTLET];
        var s = g.segments;
        var currentPath;
        
        if(s.length>1){
            currentPath=s[1].path;
            var tl = this.enter(isFreshLoad);
            tl.play();

            var navItemList = this.elem.querySelectorAll('.cb-projectbox-menu-nav-item[data-link-url="'+currentPath+'"]');
            var linkParentElem = this.elem.querySelectorAll('.cb-projectbox-menu-nav-item');
             
            for (var i=0; i< linkParentElem.length; i++) {
                var child = linkParentElem[i];
                if(child.classList.contains("-active")){
                    child.classList.remove("-active");
                }
            }

         
            //
            // navItemList.classList.add("-active");
            //console.log(navItemList[0]);
            navItemList[0].classList.add("-active");
            
               
            
            /**
                var i=o.find(".cb-projectbox-menu"),s=i.find(".cb-projectbox-menu-nav");
            var l=s.find(".cb-projectbox-menu-nav-inner");
            var c=l.find(".cb-projectbox-menu-nav-item");
            var f=c.filter(".-active"),d=i.find(".cb-projectbox-menu-filter-item");
            
            e.mobile||c.on("click",function(t){
                t.preventDefault(),t.stopPropagation();
                var o=$(this),a=o.attr("href");
                if(o.hasClass("-active"))
                    return false;
                
                l.animate({scrollLeft:.4*o.offset().left})
                c.removeClass("-active");
                o.addClass("-active");
                e.goTo({url:a});
            }),
                
            **/
            
            var navInner = this.elem.querySelector('.cb-projectbox-menu-nav-inner');
            navInner.scrollLeft = 0;
            let selectedA = navItemList[0] as HTMLAnchorElement;
          //console.log();
            var testing = selectedA?selectedA.attributes.getNamedItem('data-link-url'):null;
            
            if(testing && testing.value=='data'){
               
               // navInner.animate({scrollLeft:selectedA.offsetLeft * .4})
               navInner.scrollLeft += selectedA.offsetLeft * .4;

            }
            
            /**
            innerDiv.animate([
  // keyframes
  { transform: 'translateX(0px)' }, 
  { transform: 'translateX(-100px)' }
], { 
  // timing options
  duration: 1000
  //iterations: Infinity
});
          
            /**
            
            
            .animate([
  // keyframes
  { transform: 'translateY(0px)' }, 
  { transform: 'translateY(-300px)' }
], { 
  // timing options
  duration: 1000,
  iterations: Infinity
});
        
            **/
        }
       
        this.showFilter = currentPath=="webs";
        
        if(currentPath=="webs"){
             this.submenutype = 1;
        } else if(currentPath=="apps"){
             this.submenutype = 2;
        } else if(currentPath=="solutions"){
             this.submenutype = 3;
        }
           
       

     
    }
    
    
    
    
   /**
        public clickFilterItem(item,idx){
            //e.preventDefault(),e.stopPropagation();
            //cb-projectbox-menu-filter-item
            var t=item;
            //var n=o.find(".cb-projectbox-area").eq(t.index());
            console.log(this.projectBoxBody);
            var list1 = this.projectBoxBody.querySelectorAll(".cb-projectbox-area");
            
            var n = list1[idx];
            //console.log(this.elem);
            //console.log(list1);
            //console.log(n);
            //(this.elem.querySelector('.cb-projectbox-wrap') as HTMLElement).style.paddingTop 
            
            //var ttt = this.projectBoxBody.querySelector(".cb-projectbox-wrap"); //.css("padding-top");
//(this.elem.querySelector('.cb-projectbox-wrap') as HTMLElement).style.paddingTop 
            
            
            //var r=parseInt(o.find(".cb-projectbox-wrap").css("padding-top"))  +  (n.hasClass("aos-animate")?0:100);
            
            var r=this.projectBoxBody.style.paddingTop+ (n.classList.contains("aos-animate")?10:110);
            
            
            //Failed to execute 'scrollTo' on 'Element': parameter 1 ('options') is not an 
            
            
            //TweenLite.to(this.elem,1,{scrollTo:{y:n,offsetY:r},ease:Power2.easeInOut,autoKill:!1})

        
            var yyy = this.projectBoxBody.querySelector(".cb-container") as HTMLElement;
            //console.log(this.projectBoxBody.getBoundingClientRect().top);

           // cb-preface-grid-col -left
           // console.log("this.projectBoxBody.scrollHeight="+this.projectBoxBody.scrollTo);
            //this.projectBoxBody.scrollHeight = this.projectBoxBody.scrollTop+200;
            //this._projectboxbody.nativeElement as HTMLElement;
           // this.projectBoxBody.style.paddingTop = yyy.style.paddingTop+100;
            
            
            //console.log(ScrollToPlugin);
            //TweenLite.to(window,1,{scrollTo:{y:n,offsetY:r},ease:Power2.easeInOut,autoKill:false});
            TweenLite.to(window,1, {scrollTo:{y:n,offsetY:r},ease:Power2.easeInOut,autoKill:false});
            
            //window.scroll(0, 0);
        }

    **/

        public clickFilterItem(item,idx){
            var t=item;
            var list1 = this.projectBoxBody.querySelectorAll(".cb-projectbox-area");
            var n = list1[idx];
            var r=this.projectBoxBody.style.paddingTop+ (n.classList.contains("aos-animate")?10:110);
            TweenLite.to(window,1, {scrollTo:{y:n,offsetY:r},ease:Power2.easeInOut,autoKill:false});
            
        }

    
    
    public clickOnMenu(event:any){
        /**
        var linkElem = event.path[1];
        var linkParentElem = event.path[2];

        if(linkElem.classList.contains("-active"))
            return false;

        
        for (let child of linkParentElem.children) {
            if(child.classList.contains("-active")){
                child.classList.remove("-active");
            }
        }

        
        linkElem.classList.add("-active");
        
        **/
        
        
       // console.log(linkElem);
        //console.log(linkParentElem.children);
        
        
        
        

        
        //t.preventDefault(),t.stopPropagation();
        
        /**
       var o=$(this); 
            var a=o.attr("href");
            if(o.hasClass("-active"))
                return false;

        l.animate({scrollLeft:.4*o.offset().left});
        c.removeClass("-active");
        o.addClass("-active");
            e.goTo({url:a})
            **/
            
        /**
         e.mobile||c.on("click",function(t){
            t.preventDefault(),t.stopPropagation();
            var o=$(this); 
            var a=o.attr("href");
            if(o.hasClass("-active"))
                return false;

        l.animate({scrollLeft:.4*o.offset().left});
        c.removeClass("-active");
        o.addClass("-active");
            e.goTo({url:a})
        });
        **/
      
    }
    
  
    
    
    //services:{
    
        public enter(isFreshLoad){
            var tl=new TimelineLite({paused:true})
            var menunav = this.elem.querySelector(".cb-projectbox-menu-nav");
            var menufilter=this.elem.querySelector(".cb-projectbox-menu-filter");
            
            var i=this.filters_show(menufilter);
            var s=this.list_show();
            
            //if(!t){
            if(isFreshLoad){
                var l=this.navs_show(menunav);
                tl.add(l.play());
            }
           // }
            
            return tl.add(i.play(),"0"),
                tl.add(s.play(),0),tl;
            /**
            if(!t){
                var l=this.navs_show(menunav);
                tl.add(l.play());
            }
            
            return tl.add(i.play(),t?"0":"-=0.3"),
                tl.add(s.play(),0),tl;
                **/
        }
        
        public leave(menunav, menufilter){
            var o=new TimelineLite({paused:true});
            var n = menunav;
            var r = menufilter;
            //var a=e,n=a.find(".cb-projectbox-menu-nav");
            //var r=a.find(".cb-projectbox-menu-filter");
            return o.add(this.navs_show(n).reverse(0),0),
                o.add(this.filters_hide(r).play(),0),
                o.add(this.list_hide().play(),0),o;
            
            
            //return t||o.add(this.navs.show(n).reverse(0),0), o.add(this.filters.hide(r).play(),0),o.add(this.list.hide(a).play(),0),o;
        }
    /** not using
        public open(e){
            var t=new TimelineLite({paused:true});
            var o=e,a=o.find(".cb-projectbox-filler");
            var n=o.find(".cb-projectbox");
            return a.css({height:"100%"}),
                t.fromTo(a,.6,{scaleY:0,transformOrigin:"bottom center"},{scaleY:1,transformOrigin:"bottom center",ease:Power2.easeInOut}),
                t.set(n,{display:"none"}),
                t.fromTo(a,.6,{scaleY:1,transformOrigin:"top center"},{scaleY:0,transformOrigin:"top center",ease:Power2.easeInOut}),t;
        }
 **/
    
    
    /////////NAV
    //,navs:{
        
        public navs_show(e){
            var t=new TimelineLite({paused:true});
            var menulist = e.querySelectorAll(".cb-projectbox-menu-nav-item");
            return t.staggerFromTo(menulist,.4,{opacity:0},{opacity:1,ease:Power2.easeInOut},.05),t;
            //return n||t.staggerFromTo(a,.4,{opacity:0},{opacity:1,ease:Power2.easeInOut},.05),t;
        }
    //}
                
      
   
    
    
    
    //filters
    //filters:{
    public filters_show(e){
        var t=new TimelineLite({paused:true}); 
        if(e == null)
            return t;
        var filterlist = e.querySelectorAll(".cb-projectbox-menu-filter-item");
        //var o=e,a=o.find(".cb-projectbox-menu-filter-item");
        //return n||t.staggerFromTo(a,.4,{opacity:0},{opacity:1,ease:Power2.easeInOut},.05,.2),t;
        return t.staggerFromTo(filterlist,.4,{opacity:0},{opacity:1,ease:Power2.easeInOut},.05,.2),t;
    }
            
    public filters_hide(e){
        
        if(e == null)
            return t;
        var t=new TimelineLite({paused:true});
        var filterlist = e.querySelectorAll(".cb-projectbox-menu-filter-item");
        return t.staggerFromTo(filterlist,.2,{opacity:1},{opacity:0,ease:Power2.easeInOut},.03,.2),t;
        //var o=e,a=o.find(".cb-projectbox-menu-filter-item");
        //return n||t.staggerFromTo(a,.2,{opacity:1},{opacity:0,ease:Power2.easeInOut},.03,.2),t;
    }
    //}

    
    
     public list_show(){
        var t=new TimelineLite({paused:true});
        //if(!n){
            var a=this.elem.querySelector(".cb-preface");
         if(a != null){
            var r=a.querySelector(".cb-preface-header .cb-header h1");
            var l=a.querySelectorAll(".cb-preface-text p");

            var ul=a.querySelectorAll(".cb-preface-text h1");
            t.staggerFromTo([r],.5,{x:"-100%"},{x:"0%",clearProps:"all"},.04,.1);

            t.staggerFromTo(l,.7,{y:"300%"},{y:"0%",ease:Power2.easeOut},.04,.2);

            ul||t.staggerFromTo(ul,.7,{y:"300%"},{y:"0%",ease:Power2.easeOut},.04,.3);



         }


             
            //t.fromTo(l,.4,{opacity:0},{opacity:1},.1);
         
        //}
        return t;
    }
    
                
     public list_hide(){
        var t=new TimelineLite({paused:true});

        return t;
     }
    
    

    
    
    //,list:{
    /**
    public list_show(e){
        var t=new TimelineLite({paused:true});
if(!n){
            var o=e,a=o.find(".cb-preface"),r=a.find(".cb-preface-header .cb-header h1");
            
            var l=a.find(".cb-preface-text p");
            var i=new SplitText(r,{type:"lines"});
            var s=new SplitText(i.lines,{type:"lines"});
            var c=new SplitText(l,{type:"lines"});
            var f=new SplitText(c.lines,{type:"lines"}).lines;
            var d=a.find(".cb-preface-section .cb-header").css({display:"inline-block",overflow:"hidden",verticalAlign:"bottom"});
            u=d.find("h2"),h=a.find(".cb-preface-awards, .cb-preface-request"),v=o.find(".cb-projectbox-item").not(":first").filter(":in-viewport");
            
    t.staggerFromTo(i.lines,.5,{x:"-100%"},{x:"0%",clearProps:"all"},.04,.1),
    t.staggerFromTo(s.lines,.5,{x:"100%"},{x:"0%",clearProps:"all",onComplete:function(){i.revert()}},.04,.1),
    t.fromTo(l,.4,{opacity:0},{opacity:1},.1),
    d.length&&t.fromTo(d,.4,{opacity:0},{opacity:1},.1),
    n||(t.staggerFromTo(c.lines,.7,{y:"300%"},{y:"0%",ease:Power2.easeOut},.04,.2),
        t.staggerFromTo(f,.7,{y:"-100%"},{y:"0%",ease:Power2.easeOut,onComplete:function(){c.revert()}},.04,.2),
        d.length&&(t.staggerFromTo(d,.7,{x:"-100%"},{x:"0%",ease:Power2.easeOut},.04,.2),t.staggerFromTo(u,.7,{x:"100%"},{x:"0%",ease:Power2.easeOut},.04,.2))
       ),
    h.length&&t.fromTo(h,.4,{y:"20%",opacity:0},{y:"0%",opacity:1,clearProps:"all"},.2),
    v.length&&t.staggerFromTo(v,1,{y:"40%",opacity:0},{y:"0%",opacity:1,clearProps:"y",ease:Power2.easeInOut},.1,.1)
}
return t;
    }
                
               
    public list_hide(o){
        var t=new TimelineLite({paused:true});
        //if(!n){
            var a=o.find(".cb-preface:in-viewport"),r=o.find(".cb-projectbox-item").not(":first").filter(":in-viewport");
         
         if(a.length){
             var i=a.find(".cb-preface-header .cb-header h1");
             var c=a.find(".cb-preface-text p");
             
             var s=new SplitText(i,{type:"lines"});
             var l=new SplitText(s.lines,{type:"lines"});
             var f=new SplitText(c,{type:"lines"});
             var d=new SplitText(f.lines,{type:"lines"}).lines;
             
             var u=a.find(".cb-preface-section .cb-header");
             var h=u.find("h2"),v=a.find(".cb-preface-awards, .cb-preface-request");
             
             t.staggerFromTo(s.lines,.4,{x:"0%"},{x:"100%"},.04,.1),
                 t.staggerFromTo(l.lines,.4,{x:"0%"},{x:"-100%"},.04,.1),
                 t.fromTo(c,.4,{opacity:1},{opacity:0},.1),t.fromTo(u,.4,{opacity:1},{opacity:0},.1),
                 t.staggerFromTo(f.lines,.4,{y:"0%"},{y:"-150%",ease:Power2.easeOut},.04,.1),
                 t.staggerFromTo(d,.4,{y:"0%"},{y:"100%",ease:Power2.easeOut,onComplete:function(){f.revert()}},.04,.1),
                 u.length&&(t.staggerFromTo(u,.4,{y:"0%"},{y:"-150%"},.04,.1),t.staggerFromTo(h,.4,{y:"0%"},{y:"100%"},.04,.1)),
                 v.length&&t.fromTo(v,.4,{opacity:1},{opacity:0},.1)
         }
         r.length&&t.staggerFromTo(r,.4,{opacity:1},{opacity:0},.15,.1)
        //}

        return t;
     }
    
    

    **/
 

 
 
 
 
 
 
 
 
 
 
 
 
 
 
 /** FULL SERVICE code
                
            services:{
                enter:function(e,t){
                    var o=new TimelineLite({paused:!0}),a=e,n=a.find(".cb-projectbox-menu-nav"),r=a.find(".cb-projectbox-menu-filter"),i=this.filters.show(r),s=this.list.show(a);
                    if(!t){var l=this.navs.show(n);o.add(l.play())}
                    return o.add(i.play(),t?"0":"-=0.3"),o.add(s.play(),0),o
                },
                leave:function(e,t){
                    var o=new TimelineLite({paused:!0}),a=e,n=a.find(".cb-projectbox-menu-nav"),r=a.find(".cb-projectbox-menu-filter");
                    return t||o.add(this.navs.show(n).reverse(0),0),o.add(this.filters.hide(r).play(),0),o.add(this.list.hide(a).play(),0),o
                },
                open:function(e){
                    var t=new TimelineLite({paused:!0}),o=e,a=o.find(".cb-projectbox-filler"),n=o.find(".cb-projectbox");
                    return a.css({height:"100%"}),t.fromTo(a,.6,{scaleY:0,transformOrigin:"bottom center"},{scaleY:1,transformOrigin:"bottom center",ease:Power2.easeInOut}),t.set(n,{display:"none"}),t.fromTo(a,.6,{scaleY:1,transformOrigin:"top center"},{scaleY:0,transformOrigin:"top center",ease:Power2.easeInOut}),t
                },
                        
                navs:{
                    show:function(e){
                        var t=new TimelineLite({paused:!0}),o=e,a=o.find(".cb-projectbox-menu-nav-item");
                        return n||t.staggerFromTo(a,.4,{opacity:0},{opacity:1,ease:Power2.easeInOut},.05),t
                    }
                },
                            
                filters:{
                    show:function(e){
                        var t=new TimelineLite({paused:!0}),o=e,a=o.find(".cb-projectbox-menu-filter-item");
                        return n||t.staggerFromTo(a,.4,{opacity:0},{opacity:1,ease:Power2.easeInOut},.05,.2),t
                    },
                    hide:function(e){
                        var t=new TimelineLite({paused:!0}),o=e,a=o.find(".cb-projectbox-menu-filter-item");
                        return n||t.staggerFromTo(a,.2,{opacity:1},{opacity:0,ease:Power2.easeInOut},.03,.2),t
                    }
                },
                list:{
                    show:function(e){
                        var t=new TimelineLite({paused:!0});
                        if(!n){
                            var o=e,a=o.find(".cb-preface"),r=a.find(".cb-preface-header .cb-header h1"),i=new SplitText(r,{type:"lines"}),s=new SplitText(i.lines,{type:"lines"}),l=a.find(".cb-preface-text p"),c=new SplitText(l,{type:"lines"}),f=new SplitText(c.lines,{type:"lines"}).lines,d=a.find(".cb-preface-section .cb-header").css({display:"inline-block",overflow:"hidden",verticalAlign:"bottom"}),u=d.find("h2"),h=a.find(".cb-preface-awards, .cb-preface-request"),v=o.find(".cb-projectbox-item").not(":first").filter(":in-viewport");
                               
                            t.staggerFromTo(i.lines,.5,{x:"-100%"},{x:"0%",clearProps:"all"},.04,.1),t.staggerFromTo(s.lines,.5,{x:"100%"},{x:"0%",clearProps:"all",onComplete:function(){i.revert()}},.04,.1),t.fromTo(l,.4,{opacity:0},{opacity:1},.1),d.length&&t.fromTo(d,.4,{opacity:0},{opacity:1},.1),n||(t.staggerFromTo(c.lines,.7,{y:"300%"},{y:"0%",ease:Power2.easeOut},.04,.2),t.staggerFromTo(f,.7,{y:"-100%"},{y:"0%",ease:Power2.easeOut,onComplete:function(){c.revert()}},.04,.2),d.length&&(t.staggerFromTo(d,.7,{x:"-100%"},{x:"0%",ease:Power2.easeOut},.04,.2),t.staggerFromTo(u,.7,{x:"100%"},{x:"0%",ease:Power2.easeOut},.04,.2))),h.length&&t.fromTo(h,.4,{y:"20%",opacity:0},{y:"0%",opacity:1,clearProps:"all"},.2),v.length&&t.staggerFromTo(v,1,{y:"40%",opacity:0},{y:"0%",opacity:1,clearProps:"y",ease:Power2.easeInOut},.1,.1)
                       }
                        return t;
                    },
                    hide:function(e){
                        var t=new TimelineLite({paused:!0});
                        if(!n){
                            var o=e,a=o.find(".cb-preface:in-viewport"),r=o.find(".cb-projectbox-item").not(":first").filter(":in-viewport");if(a.length){var i=a.find(".cb-preface-header .cb-header h1"),s=new SplitText(i,{type:"lines"}),l=new SplitText(s.lines,{type:"lines"}),c=a.find(".cb-preface-text p"),f=new SplitText(c,{type:"lines"}),d=new SplitText(f.lines,{type:"lines"}).lines,u=a.find(".cb-preface-section .cb-header"),h=u.find("h2"),v=a.find(".cb-preface-awards, .cb-preface-request");t.staggerFromTo(s.lines,.4,{x:"0%"},{x:"100%"},.04,.1),t.staggerFromTo(l.lines,.4,{x:"0%"},{x:"-100%"},.04,.1),t.fromTo(c,.4,{opacity:1},{opacity:0},.1),t.fromTo(u,.4,{opacity:1},{opacity:0},.1),t.staggerFromTo(f.lines,.4,{y:"0%"},{y:"-150%",ease:Power2.easeOut},.04,.1),t.staggerFromTo(d,.4,{y:"0%"},{y:"100%",ease:Power2.easeOut,onComplete:function(){f.revert()}},.04,.1),u.length&&(t.staggerFromTo(u,.4,{y:"0%"},{y:"-150%"},.04,.1),t.staggerFromTo(h,.4,{y:"0%"},{y:"100%"},.04,.1)),v.length&&t.fromTo(v,.4,{opacity:1},{opacity:0},.1)}r.length&&t.staggerFromTo(r,.4,{opacity:1},{opacity:0},.15,.1)
                        }
                        
                        return t}
                }//list end
            }, //service end
                
                
                
                **/
}
