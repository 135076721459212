import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HeroesComponent } from './heroes/heroes.component';
import { DashboardComponent }   from './dashboard/dashboard.component';
import { HeroDetailComponent }  from './hero-detail/hero-detail.component';
import { SidemenuComponent }  from './sidemenu/sidemenu.component';
import { ProjectBoxComponent }  from './project-box/project-box.component';


import { ServiceDigitalComponent }  from './service-digital/service-digital.component';
import { ServiceMobileComponent }  from './service-mobile/service-mobile.component';
import { ServiceBrandingComponent }  from './service-branding/service-branding.component';

import { IntroComponent }  from './intro/intro.component';
import { AboutComponent }  from './about/about.component';
import { ContactComponent }  from './contact/contact.component';
import { CareerComponent }  from './career/career.component';
import { ChatComponent }  from './chat/chat.component';

import { CanDeactivateGuard } from './can-deactivate-guard.service';


const routes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    
    { path: 'home', component: IntroComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'about', component: AboutComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'contacts', component: ContactComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'chat', component: ChatComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'career', component: CareerComponent, canDeactivate: [CanDeactivateGuard] },
    
    
    {
    path: 'services', 
    component: ProjectBoxComponent,
    children: [
        {
            path:'',
            redirectTo: 'networking',
            pathMatch: 'full' 
        },
      {
        path: 'webs',
        component: ServiceDigitalComponent
      }, {
        path: 'apps',
        component: ServiceMobileComponent
       }, {
        path: 'solutions',
        component: ServiceBrandingComponent
      },{
        path: '',
        redirectTo: 'c',
        pathMatch: 'full'
      }
    ]
  },

    
    /**
    { path: 'digital', component: ProjectBoxComponent },
    { path: 'mobile', component: ProjectBoxComponent },
    **/
    
    /**
    { path: 'dashboard', component: DashboardComponent },
    { path: 'detail/:id', component: HeroDetailComponent },
  { path: 'heroes', component: HeroesComponent }**/
    
];








     	     

@NgModule({
  imports: [RouterModule.forRoot(
      routes
      , {useHash: true}
      //,{ enableTracing: true } // <-- debugging purposes only
  )],
  exports: [RouterModule],
  providers: [ 
    CanDeactivateGuard
  ]
})
export class AppRoutingModule {


    

}
