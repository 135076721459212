import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-injector',
  templateUrl: './dialog-injector.component.html',
  styleUrls: ['./dialog-injector.component.sass']
})
export class DialogInjectorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
