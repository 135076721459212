import { Component, OnInit, Renderer2 } from '@angular/core';


@Component({
  selector: 'app-service-branding',
  templateUrl: './service-branding.component.html',
  styleUrls: ['./service-branding.component.sass']
})
export class ServiceBrandingComponent implements OnInit {

  
    constructor(private renderer: Renderer2) {}

  ngOnInit() {
  }

      public onIntersection({ target, visible }: { target: Element; visible: boolean }): void {
        
        if(visible){
             if(!target.classList.contains('aos-animate'))
                this.renderer.addClass(target, 'aos-animate');
        }else{
            if(target.classList.contains('aos-animate'))
                this.renderer.removeClass(target, 'aos-animate');
        }
    }
}
