
import { Component, ElementRef, EventEmitter, NgZone, OnInit, Output, Input, ViewChild } from "@angular/core";


import { Elastic, TimelineLite, TweenLite, Power2, Linear } from "gsap";


import { Router, ActivatedRoute, ResolveEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { DialogService } from '../dialog.service'



@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.sass']
})
export class IntroComponent implements OnInit {

   dmxintros: Array<{url: string, name: string}> = [
        {url: 'services/webs', name: 'Websites'},
        {url: 'services/apps', name: 'Apps'},
       {url: 'services/solutions', name: 'Solutions'}
    ];
    
    
    
    private get elem(): HTMLElement {
      return this._elem.nativeElement;
    }
  //constructor(private _elem: ElementRef, private ngZone: NgZone) {  }
    
               
 constructor(private _elem: ElementRef, private ngZone: NgZone,
             private router: Router, private activatedRoute: ActivatedRoute,
            public dialogService: DialogService) {  
    }
    
    ngOnInit() {
    }

    
    
    canDeactivate(): Observable<boolean> | boolean | Promise<boolean>  {
        var promise = new Promise<boolean>((resolve, reject) => {
            setTimeout(() => {
                var a=this.leave();
                a.eventCallback("onComplete",function(){
                    resolve(true);
                });
                a.play();
            }, 100);
          });
          return promise;

/**
return new Promise((resolve, reject) => {
    this.confirmationService.confirm({
        message: "You have unsaved changes. Are you sure you want to leave this page?",
        accept: () => {
            resolve(true);
        },
        reject: () => {
          resolve(false);
        }
    });
});
**/

        
        
	}	
    
    
    
    
    
    
    ngAfterViewInit(){
        this.playEnter();
    }
    
    public playEnter(){
        var t=this.enter();
       // t.timeScale(.3);
        /**
        t.eventCallback("onComplete",
        () =>
			this.ngZone.run(() => {
				this._navbarAnimate = false;
			}
        ));
                 **/       
        t.play();
    }
    
    public playLeave(){
       // var o=e.view;
        var a=this.leave();
        a.eventCallback("onComplete",function(){
            //t.resolve()
        });
        a.play();
        //e.illustration&&e.illustration.pause();
        
    }

    
    public enter(){
            var t=new TimelineLite({paused:true});
            var introbg=this.elem.querySelector(".cb-intro-bg");
            var wrapper = this.elem.querySelector(".cb-slider-item");
            if(!wrapper.classList.contains("-in"))
                wrapper.classList.add("-in");
            else
                wrapper.classList.remove("-in");
        /**
            var introheader=this.elem.querySelector(".cb-header > *");
            if(introheader){
                //var r=new SplitText(introheader,{type:"words"}),i=new SplitText(r.words,{type:"words"});
                var r = this.SplitText(introheader, {words: 1, chars: 1, spacing: 10})
            }
            var introDesc=this.elem.querySelector(".cb-intro-description p");
            if(introDesc){
                //var u=new SplitText(introDesc,{type:"lines"}),h=new SplitText(u.lines,{type:"lines"});
                var u=this.SplitText(introDesc, {words: 1, chars: 1, spacing: 10});
            }
            **/
        
            var introheader=this.elem.querySelectorAll(".cb-header > *") as NodeListOf<HTMLHeadingElement>;
            if(introheader){
                var r = this.SplitText(introheader, {words: 1, chars: 0, spacing: 10})
            }
            var introDesc=this.elem.querySelectorAll(".cb-intro-description p") as NodeListOf<HTMLParagraphElement>;
            
            if(introDesc){
                //var u=new SplitText(introDesc,{type:"lines"}),h=new SplitText(u.lines,{type:"lines"});
                var u=this.SplitText(introDesc, {words: 1, chars: 0, spacing: 10});
            }
        //console.log(r);
        
        
            var s=this.elem.querySelectorAll(".cb-intro-nav-item");
            var l=this.elem.querySelectorAll(".cb-intro-nav-item-fill");
            var c=this.elem.querySelectorAll(".cb-intro-nav-item-text");
            var f=this.elem.querySelectorAll(".cb-intro-nav-item-text-mask");
            var introMore=this.elem.querySelector(".cb-intro-more")
            
        
        
            /**
            var v=o.find(".cb-intro-form form");
            if(v.length)
                var p=v.find("> .cb-form-group, > .cb-form-grid, > .cb-form-submit");
            **/
           
            /**var m=function(){
                introheader&&r.revert(),introDesc&&u.revert()
            };**/
            
            
        //introheader&&(t.staggerFromTo(introheader,.6,{y:"-420%"},{y:"0%",ease:Power2.easeInOut,force3D:true,clearProps:"y"},.1,.2)),
               
                   
           return introbg&&t.fromTo(introbg,1.5,{opacity:0},{opacity:1,ease:Power2.easeInOut},0),
               
               introheader&&(t.staggerFromTo(r.words,.4,{opacity:0,y:"-100%"},{opacity:1,y:"0%",ease:Power2.easeInOut},.07,.1)),
               
               
                s.length&&(t.staggerFromTo(s,.5,{y:"-150%"},{y:"0%",force3D:!0,clearProps:"y"},.4,.7),
                           t.staggerFromTo(c,.5,{y:"100%"},{y:"0%",force3D:!0,clearProps:"y"},.4,.7),
                           t.staggerFromTo(l,.3,{scaleX:0},{scaleX:1,ease:Linear.easeNone,force3D:true,clearProps:"all"},.3,1.4),
                           t.staggerFromTo(f,.4,{width:"0%"},{width:"100%",ease:Linear.easeNone,force3D:!0,clearProps:"all"},.4,1.6)),
               
               introDesc&&(t.staggerFromTo(u.words,.4,{opacity:0,y:"50px"},{opacity:1,y:"0px",ease:Power2.easeInOut},.07,.1)),
               
                introMore&&t.fromTo(introMore,1.2,{opacity:0},{opacity:1,clearProps:"opacity",ease:Power2.easeInOut},"-=1"),t;
        
        
            /**
            return 
                introbg&&t.fromTo(introbg,1.5,{opacity:0},{opacity:1,ease:Power2.easeInOut},0),
                //introheader&&(t.staggerFromTo(r,.6,{y:"-120%"},{y:"0%",clearProps:"y"},.1,.2),t.staggerFromTo(r,.6,{y:"100%"},{y:"0%",clearProps:"y"},.1,.2)),
                s.length&&(t.staggerFromTo(s,.5,{y:"-150%"},{y:"0%",force3D:!0,clearProps:"y"},.4,.7),t.staggerFromTo(c,.5,{y:"100%"},{y:"0%",force3D:!0,clearProps:"y"},.4,.7),
                
                t.staggerFromTo(l,.3,{scaleX:0},{scaleX:1,ease:Linear.easeNone,force3D:!0,clearProps:"all"},.3,1.4),
                
                t.staggerFromTo(f,.4,{width:"0%"},{width:"100%",ease:Linear.easeNone,force3D:!0,clearProps:"all"},.4,1.6)),
                //introDesc&&(t.staggerFromTo(u,.5,{y:"-130%"},{y:"0%",force3D:!0,clearProps:"y"},.15,1.4),t.staggerFromTo(u,.5,{y:"100%"},{y:"0%",force3D:!0,clearProps:"y"},.15,1.4)),
 
                introMore&&t.fromTo(introMore,1.2,{opacity:0},{opacity:1,clearProps:"opacity",ease:Power2.easeInOut},"-=1"),
                
                //t.add(m),
                t;
        **/
        
        
            /**
            return introbg&&t.fromTo(introbg,1.5,{opacity:0},{opacity:1,ease:Power2.easeInOut},0),
                introheader&&(t.staggerFromTo(r.words,.6,{y:"-120%"},{y:"0%",clearProps:"y"},.1,.2),t.staggerFromTo(i.words,.6,{y:"100%"},{y:"0%",clearProps:"y"},.1,.2)),
                
                s.length&&(t.staggerFromTo(s,.5,{y:"-150%"},{y:"0%",force3D:!0,clearProps:"y"},.4,.7),t.staggerFromTo(c,.5,{y:"100%"},{y:"0%",force3D:!0,clearProps:"y"},.4,.7),t.staggerFromTo(l,.3,{scaleX:0},{scaleX:1,ease:Linear.easeNone,force3D:!0,clearProps:"all"},.3,1.4),t.staggerFromTo(f,.4,{width:"0%"},{width:"100%",ease:Linear.easeNone,force3D:!0,clearProps:"all"},.4,1.6)),
                
                introDesc&&(t.staggerFromTo(u.lines,.5,{y:"-130%"},{y:"0%",force3D:!0,clearProps:"y"},.15,1.4),t.staggerFromTo(h.lines,.5,{y:"100%"},{y:"0%",force3D:!0,clearProps:"y"},.15,1.4)),
                
               // v.length&&t.staggerFromTo(p,.8,{opacity:0,y:"60px"},{opacity:1,y:"0px",ease:Power2.easeInOut,clearProps:"opacity, y"},.1,.3),
                
                introMore&&t.fromTo(introMore,1.2,{opacity:0},{opacity:1,clearProps:"opacity",ease:Power2.easeInOut},"-=1"),
                
                t.add(m),t;
            **/
            
        }
    
    public leave(){
        console.log("in leave 1");
        var o=new TimelineLite({paused:true});
        
        var introbg=this.elem.querySelector(".cb-intro-bg");
        var wrapper = this.elem.querySelector(".cb-slider-item");
        //var r=this.elem.querySelector(".cb-header > *");
        
        //if(r.length){
        //    var i=new SplitText(r,{type:"words"}),s=new SplitText(i.words,{type:"words"});
        //}
        
        var l=this.elem.querySelectorAll(".cb-intro-nav-item");
        
        if(l.length){
            var c=(this.elem.querySelectorAll(".cb-intro-nav-item-fill"),this.elem.querySelectorAll(".cb-intro-nav-item-text"),this.elem.querySelectorAll(".cb-intro-nav-item-text-mask"),this.elem.querySelectorAll(".cb-intro-nav-item-inner"));
        }
        
        //var f=a.find(".cb-intro-description p");
        //if(f.length){
        //    var d=new SplitText(f,{type:"lines"}),u=new SplitText(d.lines,{type:"lines"});
        //}
        
        //var h=a.find(".cb-intro-form form");
        
        //if(h.length)
        //    var v=h.find("> .cb-form-group, > .cb-form-grid, > .cb-form-submit");
        
        
        var introMore=this.elem.querySelector(".cb-intro-more")
        //var g=function(){r.length&&i.revert(),f.length&&d.revert()};
        
                return   introMore&&o.fromTo(introMore,.5,{y:"0%",opacity:1},{y:"100%",opacity:0,ease:Power2.easeInOut},0),
               l.length&&(o.staggerFromTo(l,.4,{y:"0%"},{y:"-200%",force3D:!0},.05,0),o.staggerFromTo(c,.4,{y:"0%"},{y:"150%",force3D:!0},.05,0)), introbg&&o.fromTo(introbg,.4,{scale:1,opacity:1},{scale:.8,opacity:0,ease:Power2.easeInOut},.1), o&&o.fromTo(wrapper,.4,{x:"0%"},{x:"100%",ease:Power2.easeInOut},.6), o;
        
        /**
        return 
        introMore&&o.fromTo(introMore,.5,{y:"0%",opacity:1},{y:"100%",opacity:0,ease:Power2.easeInOut},0),
            
            //r.length&&(o.staggerFromTo(i.words,.4,{y:"0%"},{y:"-120%",force3D:!0},.05,0),o.staggerFromTo(s.words,.4,{y:"0%"},{y:"100%",force3D:!0},.05,0)),
            
            l.length&&(o.staggerFromTo(l,.4,{y:"0%"},{y:"-200%",force3D:!0},.05,0),o.staggerFromTo(c,.4,{y:"0%"},{y:"150%",force3D:!0},.05,0)),
            
            //f.length&&(o.staggerFromTo(d.lines,.5,{y:"0%"},{y:"-200%",force3D:!0},.05,.1),o.staggerFromTo(u.lines,.5,{y:"0%"},{y:"100%",force3D:!0},.05,.1)),
            
            //h.length&&o.staggerFromTo(v,.4,{opacity:1,y:"0px"},{opacity:0,y:"-50px",ease:Power2.easeInOut},.07,.1),
            
            introbg&&o.fromTo(introbg,.4,{scale:1,opacity:1},{scale:.8,opacity:0,ease:Power2.easeInOut},.1),
            
            o&&o.fromTo(wrapper,.4,{x:"0%"},{x:"100%",ease:Power2.easeInOut},.6),
            //o.eventCallback("onComplete",g),o.eventCallback("onReverseComplete",g),
            o;
        **/
        /**
        var o=new TimelineLite({paused:!0}),a=e.find(".cb-intro"),n=a.find(".cb-intro-bg"),r=a.find(".cb-header > *");if(r.length)var i=new SplitText(r,{type:"words"}),s=new SplitText(i.words,{type:"words"});var l=a.find(".cb-intro-nav-item");if(l.length)var c=(l.find(".cb-intro-nav-item-fill"),l.find(".cb-intro-nav-item-text"),l.find(".cb-intro-nav-item-text-mask"),l.find(".cb-intro-nav-item-inner"));var f=a.find(".cb-intro-description p");if(f.length)var d=new SplitText(f,{type:"lines"}),u=new SplitText(d.lines,{type:"lines"});var h=a.find(".cb-intro-form form");if(h.length)var v=h.find("> .cb-form-group, > .cb-form-grid, > .cb-form-submit");var p=a.find(".cb-intro-more"),g=function(){r.length&&i.revert(),f.length&&d.revert()};return p.length&&o.fromTo(p,.5,{y:"0%",opacity:1},{y:"100%",opacity:0,ease:Power2.easeInOut},0),r.length&&(o.staggerFromTo(i.words,.4,{y:"0%"},{y:"-120%",force3D:!0},.05,0),o.staggerFromTo(s.words,.4,{y:"0%"},{y:"100%",force3D:!0},.05,0)),l.length&&(o.staggerFromTo(l,.4,{y:"0%"},{y:"-200%",force3D:!0},.05,0),o.staggerFromTo(c,.4,{y:"0%"},{y:"150%",force3D:!0},.05,0)),f.length&&(o.staggerFromTo(d.lines,.5,{y:"0%"},{y:"-200%",force3D:!0},.05,.1),o.staggerFromTo(u.lines,.5,{y:"0%"},{y:"100%",force3D:!0},.05,.1)),h.length&&o.staggerFromTo(v,.4,{opacity:1,y:"0px"},{opacity:0,y:"-50px",ease:Power2.easeInOut},.07,.1),n.length&&o.fromTo(n,.4,{scale:1,opacity:1},{scale:.8,opacity:0,ease:Power2.easeInOut},.1),t&&o.fromTo(e,.4,{x:"0%"},{x:"100%",ease:Power2.easeInOut},.6),o.eventCallback("onComplete",g),o.eventCallback("onReverseComplete",g),o
        **/
        
    }
    
    
    
    
    
    
    
    
    
    
        
                
                /**
                leave:function(e,t){var o=new TimelineLite({paused:!0}),a=e.find(".cb-intro"),n=a.find(".cb-intro-bg"),r=a.find(".cb-header > *");if(r.length)var i=new SplitText(r,{type:"words"}),s=new SplitText(i.words,{type:"words"});var l=a.find(".cb-intro-nav-item");if(l.length)var c=(l.find(".cb-intro-nav-item-fill"),l.find(".cb-intro-nav-item-text"),l.find(".cb-intro-nav-item-text-mask"),l.find(".cb-intro-nav-item-inner"));var f=a.find(".cb-intro-description p");if(f.length)var d=new SplitText(f,{type:"lines"}),u=new SplitText(d.lines,{type:"lines"});var h=a.find(".cb-intro-form form");if(h.length)var v=h.find("> .cb-form-group, > .cb-form-grid, > .cb-form-submit");var p=a.find(".cb-intro-more"),g=function(){r.length&&i.revert(),f.length&&d.revert()};return p.length&&o.fromTo(p,.5,{y:"0%",opacity:1},{y:"100%",opacity:0,ease:Power2.easeInOut},0),r.length&&(o.staggerFromTo(i.words,.4,{y:"0%"},{y:"-120%",force3D:!0},.05,0),o.staggerFromTo(s.words,.4,{y:"0%"},{y:"100%",force3D:!0},.05,0)),l.length&&(o.staggerFromTo(l,.4,{y:"0%"},{y:"-200%",force3D:!0},.05,0),o.staggerFromTo(c,.4,{y:"0%"},{y:"150%",force3D:!0},.05,0)),f.length&&(o.staggerFromTo(d.lines,.5,{y:"0%"},{y:"-200%",force3D:!0},.05,.1),o.staggerFromTo(u.lines,.5,{y:"0%"},{y:"100%",force3D:!0},.05,.1)),h.length&&o.staggerFromTo(v,.4,{opacity:1,y:"0px"},{opacity:0,y:"-50px",ease:Power2.easeInOut},.07,.1),n.length&&o.fromTo(n,.4,{scale:1,opacity:1},{scale:.8,opacity:0,ease:Power2.easeInOut},.1),t&&o.fromTo(e,.4,{x:"0%"},{x:"100%",ease:Power2.easeInOut},.6),o.eventCallback("onComplete",g),o.eventCallback("onReverseComplete",g),o}}
    **/
    
    
    
/* SplitText Plugin - Starts */
SplitText(element, options) {
    var typetype;
	if (!options.hasOwnProperty("words")) options.words = 1;
	if (!options.hasOwnProperty("chars")) options.chars = 1;
	if (!options.hasOwnProperty("spacing")) options.spacing = 5;
	var searchTextNodes = function(element) {
		var foundTextNodes = [];
		if (element == null || element == undefined || element.item == null || element.item == undefined) 
            return foundTextNodes;
        /**
		for (var i=0; i<=element.childNodes.length-1; i++) {
			var node = element.childNodes[i];
			if (node.nodeName == "#text") { //text found
				foundTextNodes.push(node);
			} else {
				var foundTextNodes = foundTextNodes.concat(this.searchTextNodes(node));
			}
		}**/
        //console.log(element);
        for (var i=0; i<=element.item.length-1; i++) {
			var node = element[i];
            console.log(node.nodeName);
			//if (node.nodeName == "#text") { //text found
            if (node.nodeName == "H2"){
				foundTextNodes.push(node);
                typetype = "H2";
			} else if (node.nodeName == "P"){
				foundTextNodes.push(node);
                typetype = "P";
			} else {
				var foundTextNodes = foundTextNodes.concat(searchTextNodes(node));
			}
		}
        
		return foundTextNodes;
	}
	var createElement = function(text, relatedNode) {
		var node = document.createElement(typetype=="P"?"div":typetype);
        //console.log(text);
		var nodeText = document.createTextNode(text);
        //console.log(nodeText);
		//node.innerHTML = nodeText.textContent;
		node.appendChild(nodeText);
		node.style.display = "inline-block";
		node.style.position = "relative";
		if (text.trim() == "") 
            node.style.width = String(options.spacing) + "px";
        //console.log(node);
        if(relatedNode && relatedNode.parentNode)
		  relatedNode.parentNode.insertBefore(node, relatedNode);
		return node;
	}
	var splitCharacters = function(textNode) {
		var characters = textNode; //textNode.nodeValue.toString();
		var chars = [];
		if (characters.trim() != "") {
			for (var c=0; c<=characters.length-1; c++) {
				var character = characters.substr(c, 1)
				var char = createElement(character, textNode);
				if (character.trim() != "") chars.push(char);
			}
            if(textNode && textNode.parentNode)
			 textNode.parentNode.removeChild(textNode);
		}
		return chars;
	}
	var splitWords = function(textNode) {
        
		//var textWords = textNode.nodeValue.toString().split(" ");
        var textWords = textNode.textContent.split(" ");
        //console.log("textWords");
        //console.log(textWords);
		var words = [];
		for (var w=0; w<=textWords.length-1; w++) {
            //console.log(w);
			var textWord = textWords[w];
			var word = createElement(textWord, textNode);
            //console.log(word);
			if (textWord.trim() != "") 
                words.push(word);
			if (w < textWords.length-1)
                createElement(" ", textNode); //spacing for word
		}
        if(textNode && textNode.parentNode)
		  textNode.parentNode.removeChild(textNode);
		return words;
	}
	var splitTextNodes = function(textNodes) {
		var splitText = {words: [], chars: []};
		for (var i=0; i<=textNodes.length-1; i++) {
			var textNode = textNodes[i];
			if (options.words == 0) {
				splitText.chars = splitText.chars.concat(splitCharacters(textNode));
			} else {
            
				var words = splitWords(textNode);
				if (options.chars == 1) {
					for (var w=0; w<=words.length-1; w++) {
						var word = words[w];
						var chars = splitCharacters(word.innerHTML);
						splitText.chars = splitText.chars.concat(chars);
						word.chars = chars;
					}
				}
				splitText.words = splitText.words.concat(words);
			}
		}
		return splitText;
	}
	var textNodes = searchTextNodes(element);
	var splitText = splitTextNodes(textNodes);
	return splitText;
}
    
    
    
    

}
