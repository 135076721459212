import { Component, OnInit, ViewChild, ViewContainerRef, ElementRef, ViewEncapsulation, 
        ComponentFactoryResolver, AfterViewChecked } from '@angular/core';

import * as AWSSDK from 'aws-sdk';

import { of, timer, Subject } from 'rxjs';
import { debounce, debounceTime } from 'rxjs/operators';


import { faStar as farStar, faArrowAltCircleRight as farArrowAltCircleRight, 
        faSmile, faMeh, faFrown } from '@fortawesome/free-regular-svg-icons';
import { faStar as fasStar, faArrowAltCircleRight as fasArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';

import { FaIconComponent } from '@fortawesome/angular-fontawesome';

import { ChatMessageComponent } from '../chat-message/chat-message.component';


import { MessageList, MessageListItem, MessageListType } from '../chatbot/message-list'

/**
import { DialogService } from '../chatbot/dialog.service'
import { ExampleComponent } from '../example/example.component'
**/


import { DialogConfig } from '../chatbot/dialog-config'
import { DialogRef } from '../chatbot/dialog-ref'


@Component({
  selector: 'app-chat',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.sass']
})
export class ChatComponent implements OnInit, AfterViewChecked {  
    
    faArrowAltCircleRight = farArrowAltCircleRight;
    faSmile = faSmile;
    faMeh = faMeh;
    faFrown = faFrown;
    
    faStar = farStar;
    messageInput: string;
    messageInputChanged: Subject<string> = new Subject<string>();

    MESSAGE_INPUT_FIELD: HTMLInputElement;
    CHAT_MESSAGE_COLUMN: HTMLDivElement;
    MESSAGE_INPUT: HTMLDivElement;
    CHAT_BOT_MOOD: HTMLDivElement;
    CHAT_BOT_MOOD_VALUE: HTMLHeadingElement;

    STATE: any = {
      isUserSendingMessage: false,
      isChatBotSendingMessage: false,
  
      moods: ['friendly', 'suspicious', 'boastful'],
      moodIcons: [faSmile, faMeh, faFrown],
      currentMood: '',
      currentMoodIcon: null,
      chatbotMessageIndex: 0,
      nLetterSets: 4
    }
    
    sessionAttributes = {};
    lexRuntime: AWSSDK.LexRuntime;
    lexUserId = 'userID' + Date.now(); // Client application userID
    


    @ViewChild('scrollMe') private myScrollContainer: ElementRef;
    @ViewChild('vcRef', { read:   ViewContainerRef })
    private vcRef: ViewContainerRef;
    
    private latestReceivedMessage: ChatMessageComponent;

    
    constructor(private el: ElementRef, private cfr: ComponentFactoryResolver,
               public config: DialogConfig, public dialog: DialogRef) {
                //, public dialog: DialogService) {
        this.messageInputChanged.pipe(debounceTime(500))
            .subscribe(model => {
                this.toggleInput()
                //console.log(model);
            });
        
        
        /**
        const ref = this.dialog.open(ExampleComponent, {
          data: { message: 'I am a dynamic component inside of a dialog!' },
        })
        
        const sub = ref.afterClosed.subscribe(result => {
          console.log('Dialog closed', result)
            sub.unsubscribe();
        })
         **/
        
       
    }
    private get elem(): HTMLElement {
      return this.el.nativeElement;
    }
    
    /**
MESSAGE_INPUT_FIELD.onkeypress = e => {
  if(checkIfInputFieldHasVal() && e.key === 'Enter'){
    removeClass(MESSAGE_INPUT, 'send-enabled')
    if(canSendMessage()){
      onEnterPress(e)
    }
  }
}

MESSAGE_INPUT_FIELD.onkeyup = () => {
  toggleInput()
}

**/
      onClose() {
        this.dialog.close('some value')
      }
    msgChanged(text: string) {
        this.messageInputChanged.next(text);
        
        
    }
    eventHandler(event) {
        //MESSAGE_INPUT_FIELD.onkeypress = e => {
          if(this.checkIfInputFieldHasVal() && event.key === 'Enter'){
                this.removeClass(this.MESSAGE_INPUT, 'send-enabled')
                if(this.canSendMessage()){
                  this.onEnterPress(event)
                }
          }
       // }
/**
MESSAGE_INPUT_FIELD.onkeyup = () => {
  toggleInput()
}
        **/
    } 

  addMessageComponent(text: string, isReceived: boolean) {
    const factory = this.cfr.resolveComponentFactory(ChatMessageComponent);
    const component = this.vcRef.createComponent(factory);
    component.instance.message = text;
    component.instance.isReceived = isReceived;
      
    
    if(isReceived){
        this.latestReceivedMessage = component.instance
        
        /**
        this.latestReceivedMessage.list = { title:"testing", description:"testing",
                           type:MessageListType.BUTTON,
                           items:[{title:'Cardiology', description:'Cardiology'},
                             {title:'Oncology', description:'Oncology'},
                             {title:'General Surgery', description:'General Surgery'},
                             {title:'Neurology', description:'Neurology'}]
                          }
                          **/
        
        
        //component.instance.clickMessageOption.ne
        this.latestReceivedMessage.clickMessageOption.subscribe(v => {
            //console.log(v);
            this.addMessageComponent(v.title, false)
            this.sendMessageToServer(v.title)
        });

    }else{
        this.latestReceivedMessage.isActive = false;
    }
  
      

    if(this.STATE.currentMood)
        component.instance.mood = this.STATE.currentMood;

    component.instance.ngOnChanges({});

  }


    ngAfterViewChecked() {        
        this.scrollToBottom();        
    } 

    scrollToBottom() {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    }
    
    
      ngOnInit() {
                
            AWSSDK.config.region = 'us-east-1'; // Region
            AWSSDK.config.credentials = new AWSSDK.CognitoIdentityCredentials({
                IdentityPoolId: 'us-east-1:61dfe8ae-41f3-4b35-8f92-7924afa8e4a7'//your Identity poolId
            });
            this.lexRuntime = new AWSSDK.LexRuntime();
          
          
            this.MESSAGE_INPUT_FIELD = this.elem.querySelector("#message-input-field"); 
            console.log(this.MESSAGE_INPUT_FIELD);
         
            this.CHAT_MESSAGE_COLUMN = this.elem.querySelector('#chat-message-column');
            console.log(this.CHAT_MESSAGE_COLUMN);
            this.MESSAGE_INPUT = this.elem.querySelector('#message-input');
            console.log(this.MESSAGE_INPUT);
            this.CHAT_BOT_MOOD = this.elem.querySelector('#chat-bot-mood');
            console.log(this.CHAT_BOT_MOOD);
            this.CHAT_BOT_MOOD_VALUE = this.elem.querySelector('#chat-bot-mood-value');
            console.log(this.CHAT_BOT_MOOD_VALUE);

          
            this.setChatbotMood()
            this.sendChatbotMessage('Hello, What can I help you with?')
            this.toggleInput()
            this.setMoodInterval(this.getRandMoodInterval())
            
          this.messageInput = ""
            console.log("--->"+this.messageInput);

      }
    
    
   



    
  //  const LETTER_POOL = getEl('letter-pool');
//     const TEMP_LETTER_POOL = getEl('temp-letter-pool');
 //    const LETTER_OVERLAY = getEl('letter-overlay');
//     const CHAT_MESSAGE_COLUMN_WRAPPER = getEl('chat-message-column-wrapper');

    
    //const MESSAGE_INPUT_FIELD = getEl('message-input-field');



    getRandMood = () => {
      const rand = this.getRand(1, 3)
      return rand - 1
      //return this.STATE.moods[rand - 1]
    }
    
  
    setChatbotMood = () => {
        //this.STATE.currentMood = this.getRandMood()
        
        let moodIndex = this.getRandMood()
        this.STATE.currentMood = this.STATE.moods[moodIndex]
        this.STATE.currentMoodIcon = () => { return this.STATE.moodIcons[moodIndex] }
        
      
      for(let i = 0; i < this.STATE.moods.length; i++){
        this.removeClass(this.CHAT_BOT_MOOD, this.STATE.moods[i])
      }
      this.addClass(this.CHAT_BOT_MOOD, this.STATE.currentMood)
      this.CHAT_BOT_MOOD_VALUE.innerHTML = this.STATE.currentMood
    }


 getRandGreeting = () => {
  let rand = 0
  switch(this.STATE.currentMood){
    case 'friendly':
      rand = this.getRand(1, this.greetings.friendly.length)
      return this.greetings.friendly[rand - 1]
    case 'suspicious':
      rand = this.getRand(1, this.greetings.suspicious.length)
      return this.greetings.suspicious[rand - 1]
    case 'boastful':
      rand = this.getRand(1, this.greetings.boastful.length)
      return this.greetings.boastful[rand - 1]
    default:
      break
  }
}

 getRandConvo = () => {
  let rand = 0
  switch(this.STATE.currentMood){
    case 'friendly':
      rand = this.getRand(1, this.convo.friendly.length)
      return this.convo.friendly[rand - 1]
    case 'suspicious':
      rand = this.getRand(1, this.convo.suspicious.length)
      return this.convo.suspicious[rand - 1]
    case 'boastful':
      rand = this.getRand(1, this.convo.boastful.length)
      return this.convo.boastful[rand - 1]
    default:
      break
  }
}

//const scrollToBottomOfMessages = () => {
//  CHAT_MESSAGE_COLUMN_WRAPPER.scrollTop = CHAT_MESSAGE_COLUMN_WRAPPER.scrollHeight
//}


 appendContentText = (contentText, text) => {
  for(let i = 0; i < text.length; i++){
    const letter = document.createElement('span')
    letter.innerHTML = text[i]
    this.setAttr(letter, 'data-letter', text[i])
    contentText.appendChild(letter)
  }
}

 createChatMessage = (text, isReceived) => {
  let message = document.createElement('div'),
      profileIcon = document.createElement('div'),
      icon = document.createElement('i'),
      content = document.createElement('div'),
      contentText = document.createElement('h1'),
      direction = isReceived ? 'received' : 'sent'
  
  this.addClass(content, 'content')
  this.addClass(content, 'invisible')
  this.addClass(contentText, 'text')
  this.addClass(contentText, 'invisible')
  this.appendContentText(contentText, text)
  content.appendChild(contentText)
  
  this.addClass(profileIcon, 'profile-icon')
  this.addClass(profileIcon, 'invisible')
  profileIcon.appendChild(icon)
  
  this.addClass(message, 'message')
  this.addClass(message, direction)
  
  if(isReceived){
    this.addClass(icon, 'fab')
    this.addClass(icon, 'fa-cloudsmith')
    this.addClass(message, this.STATE.currentMood)
    message.appendChild(profileIcon)
    message.appendChild(content)
  }
  else{
    this.addClass(icon, 'far')
    this.addClass(icon, 'fa-user')
    
    message.appendChild(content)
    message.appendChild(profileIcon)
  }
  
  return message
}




 addChatMessage = (text, isReceived) => {
     
  const message = this.createChatMessage(text, isReceived),
        content = message.getElementsByClassName('content')[0],
        contentText = content.getElementsByClassName('text')[0],
        profileIcon = message.getElementsByClassName('profile-icon')[0]
  
  this.CHAT_MESSAGE_COLUMN.appendChild(message)
  this.toggleInput()
  
  setTimeout(() => {
    this.removeClass(profileIcon, 'invisible')
    setTimeout(() => {
      this.removeClass(content, 'invisible')
      this.removeClass(contentText, 'invisible')
    }, 250)
  }, 250)
  
}
 
 

 checkIfInputFieldHasVal = () => this.MESSAGE_INPUT_FIELD.value.length > 0

 clearInputField = () => {
  this.MESSAGE_INPUT_FIELD.value = ''
}

 disableInputField = () => {
  this.MESSAGE_INPUT_FIELD.blur()
  this.MESSAGE_INPUT_FIELD.value = ''
  this.MESSAGE_INPUT_FIELD.readOnly = true
}

 enableInputField = () => {
  this.MESSAGE_INPUT_FIELD.readOnly = false
  this.MESSAGE_INPUT_FIELD.focus()
}

 getChatbotMessageText = () => {
  if(this.STATE.chatbotMessageIndex === 0){
    return this.getRandGreeting()
  }
  else{
    return this.getRandConvo()
  }
}

 sendChatbotMessage = (chatMessage) => {
     
     if(!chatMessage){
         chatMessage = this.getChatbotMessageText()
     }
  
  this.STATE.isChatBotSendingMessage = true
  
  this.addMessageComponent(chatMessage, true);
  //this.addChatMessage(text, true)
  this.STATE.chatbotMessageIndex++
  setTimeout(() => {
    this.STATE.isChatBotSendingMessage = false
    this.toggleInput()
  }, 4000)
}

 sendUserMessage = () => {
  const text = this.MESSAGE_INPUT_FIELD.value
  this.STATE.isUserSendingMessage = true
  
  //this.addChatMessage(text, false)
  this.addMessageComponent(text, false);
     
  setTimeout(() => {
    this.STATE.isUserSendingMessage = false
    this.toggleInput()
  }, 4000)
}

 
 onEnterPressBk = e => {
  this.sendUserMessage()
  /**
  setTimeout(() => {
    this.sendChatbotMessage()
  }, 4000)
  this.toggleInput()
  this.clearInputField()
  **/
}
 
 
onEnterPress = e => {
    const _message = this.MESSAGE_INPUT_FIELD.value
    if (_message !== '') {
          this.STATE.isUserSendingMessage = true
          this.clearInputField()
          
          
          //check here
          this.addMessageComponent(_message, false);
          this.sendMessageToServer(_message);
        
    }
}
    

sendMessageToServer(msg){
       const params = {
          botAlias: '$LATEST', // 
          botName: 'MakeAppointmentMOBILEHUB', // your chatbot name 
          userId: this.lexUserId,
          inputText: msg,
          sessionAttributes: this.sessionAttributes
        };

        this.lexRuntime.postText(params, (err, data) => {
          if (err) {
             console.log(err, err.stack);
          }
          if (data) {
             this.sessionAttributes = data.sessionAttributes;
              
                this.STATE.isChatBotSendingMessage = true
                this.addMessageComponent(data.message, true);
                  //this.addChatMessage(text, true)
                this.STATE.chatbotMessageIndex++
                setTimeout(() => {
                    this.STATE.isChatBotSendingMessage = false
                    this.toggleInput()
                    
                    this.STATE.isUserSendingMessage = false
                    
                }, 200)

  
          }
        });
}
    
 

resetTimeout = null;
toggleInput = () => {
  if(this.checkIfInputFieldHasVal() && this.canSendMessage()){
    this.addClass(this.MESSAGE_INPUT, 'send-enabled')
  }
  else{
    this.removeClass(this.MESSAGE_INPUT, 'send-enabled')
  }
}

 isValidLetter = e => {
  return !e.ctrlKey 
    && e.key !== 'Enter'
    && e.keyCode !== 8
    && e.keyCode !== 9
    && e.keyCode !== 13
}

 canSendMessage = () => !this.STATE.isUserSendingMessage && !this.STATE.isChatBotSendingMessage

 getRandMoodInterval = () => this.getRand(20000, 40000)

moodInterval = null
 setMoodInterval = time => {
  this.moodInterval = setInterval(() => {
    clearInterval(this.moodInterval)
    this.setChatbotMood()
    this.setMoodInterval(this.getRandMoodInterval())
  }, time)
}


//MESSAGE_INPUT_FIELD.oncut = () => toggleInput()

//window.onload = () => init()

//window.onfocus = () => resetLetterPool()

//window.onresize = _.throttle(resetLetterPool, 200)

 greetings = {
  friendly: [
    "Hiya, pal. I hope you're having a terrific day!",
    "Good day to you, friend!"
  ],
  suspicious: [
    "Hmm, I would introduce myself, but I'm not so sure thats a good idea.",
    "Hello, how are you? Wait, don't answer that, I have no way of verifying your response!"
  ],
  boastful: [
    "Hey, did I mention I am built on JavaScript? Which is the greatest language ever by the way!",
    "Good day to you. Though I must say that I am having a GREAT day!"
  ]
}

 convo = {
  friendly: [
    "What a great thing you just said. I'm so glad you said it.",
    "Ahh, yes, I agree. It is so great to say things, isn't it?",
    "Please, tell me more. It brings me such joy to respond to the things you say.",
    "Ahh, yes valid point. Or was it? Either way, you're fantastic!",
    "Anyways, did I mention that I hope you're having a great day? If not, I hope it gets better!"
  ],
  suspicious: [
    "I just don't know if I can trust that thing you just said...",
    "Oh, interesting. I totally believe you. (Not really)",
    "Uh-huh, yeah, listen...I'm not going to fully invest in this conversation until I'm certain I know your motive.",
    "Wait, what the heck is that?? Oh, phewf, it's just another rogue letter 'R' that escaped the letter pool.",
    "You can't fool me, I know that's not true!"
  ],
  boastful: [
    "That's interesting. I'll have you know that I have an extremely advanced learning algorithm that analyzes everything you say...well, not really, but I wish.",
    "Hey, while I have you, I should probably tell you that I can respond in 4 seconds flat. Which is pretty fast if you ask me.",
    `Listen, that's neat and all, but look how fast I can calculate this math problem: 12345 * 67890 = ${12345 * 67890}. Didn't even break a sweat.`,
    "Oh, I forgot to mention that I've existed for over 100,000 seconds and that's something I'm quite proud of.",
    "Wow, thats pretty cool, but I can hold my breath for all of eternity. And it took me 0 seconds to gain that ability."
  ]
}
    
    

 getEl = el => document.getElementById(el)
 setStyle = (el, prop, val) => el.style[prop] = val
 setAttr = (el, attr, val) => el.setAttribute(attr, val)
 hasClass = (el, className) => el.classList.contains(className)
 addClass = (el, className) => {
  if(!this.hasClass(el, className))
    el.classList.add(className)
}
 removeClass = (el, className) => {
     console.log(el);
  if(this.hasClass(el, className))
    el.classList.remove(className)
}
 resetStyles = el => el.removeAttribute('style')
 removeChild = (el, child) => {
  if(child.parentNode === el) el.removeChild(child)
}
 removeAllChildren = el => {
  while (el.hasChildNodes()) el.removeChild(el.lastChild)
}

 getElPos = el => {
  const offset = el.getBoundingClientRect()
  return {
    left: offset.left,
    top: offset.top
  }
}

 setElPos = (el, x, y) => {
  this.setStyle(el, 'left', x + 'px')
  this.setStyle(el, 'top', y + 'px')
}

 getRand = (min, max) => Math.floor(Math.random() * max) + min

 getRandExcept = (min, max, exception) => {
  const rand = this.getRand(min, max)
  return rand === exception ? this.getRandExcept(min, max, exception) : rand
}

 getRandPosOffScreen = overrideQuadrant => {
  const lowX1 = 0 - (window.innerWidth * 0.2),
        highX1 = 0 - (window.innerWidth * 0.1),
        lowY1 = 0,
        highY1 = window.innerHeight,
        
        lowX2 = window.innerWidth * 1.1,
        highX2 = window.innerWidth * 1.2,
        lowY2 = 0,
        highY2 = window.innerHeight,
        
        lowX3 = 0,
        highX3 = window.innerWidth,
        lowY3 = 0 - (window.innerHeight * 0.2),
        highY3 = 0 - (window.innerHeight * 0.1),
        
        lowX4 = 0,
        highX4 = window.innerWidth,
        lowY4 = window.innerHeight * 1.1,
        highY4 = window.innerHeight * 1.2
  
  let rand = Math.floor((Math.random() * 4) + 1)
  
  if(overrideQuadrant){
    rand = overrideQuadrant
  }
  
  let x = 0,
      y = 0
  
  switch(rand){
    case 1:
      x = Math.floor(Math.random() * (highX1 - lowX1 + 1)) + lowX1
      y = Math.floor(Math.random() * (highY1 - lowY1)) + lowY1
      break
    case 2:
      x = Math.floor(Math.random() * (highX2 - lowX2 + 1)) + lowX2
      y = Math.floor(Math.random() * (highY2 - lowY2)) + lowY2
      break
    case 3:
      x = Math.floor(Math.random() * (highX3 - lowX3 + 1)) + lowX3
      y = Math.floor(Math.random() * (highY3 - lowY3)) + lowY3
      break
    case 4:
      x = Math.floor(Math.random() * (highX4 - lowX4 + 1)) + lowX4
      y = Math.floor(Math.random() * (highY4 - lowY4)) + lowY4
      break
  }
  
  return { x, y }
}

 resetAllTimeouts = () => {
  let id = window.setTimeout(() => {}, 0)
  while(id--) {
    window.clearTimeout(id)
  }
}

 resetAllIntervals = () => {
  let id = window.setInterval(() => {}, 0)
  while(id--) {
    window.clearInterval(id)
  }
}
    
    
    
}
