import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-service-digital',
  templateUrl: './service-digital.component.html',
  styleUrls: ['./service-digital.component.sass']
})
export class ServiceDigitalComponent implements OnInit {
    

    constructor(private renderer: Renderer2) {}
    ngOnInit() {
    }
    
    public onIntersection({ target, visible }: { target: Element; visible: boolean }): void {
        
        if(visible){
             if(!target.classList.contains('aos-animate'))
                this.renderer.addClass(target, 'aos-animate');
        }else{
            if(target.classList.contains('aos-animate'))
                this.renderer.removeClass(target, 'aos-animate');
        }
    }

}
