import { Component, ElementRef, EventEmitter, NgZone, OnInit, Output, Input, ViewChild } from "@angular/core";

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HeroService }  from '../hero.service';


import { Elastic, TimelineLite, TweenLite, Bounce } from "gsap";
import { Hero } from '../hero';


@Component({
  selector: 'app-hero-detail',
  templateUrl: './hero-detail.component.html',
  styleUrls: ['./hero-detail.component.sass']
})
export class HeroDetailComponent implements OnInit {
    
    @Input() hero: Hero;

    @Output() toggled: EventEmitter<TimelineLite> = new EventEmitter();
     

	public get isActive(): boolean {
		return this._active;
	}

	public get isAnimating(): boolean {
		return this._animating;
	}

     
     
	private _active: boolean;
	private _animating: boolean;
	@ViewChild("light")
	private _light: ElementRef;
     

	private get light(): HTMLElement {
		return this._light.nativeElement;
	}
     
	constructor(
        private ngZone: NgZone,
        private route: ActivatedRoute,
        private heroService: HeroService,
        private location: Location
               ) {}

	ngOnInit() {
        
        this.getHero();
		this.setState();
        
	}
    getHero(): void {
          const id = +this.route.snapshot.paramMap.get('id');
          this.heroService.getHero(id)
            .subscribe(hero => this.hero = hero);
        }
     
    public activate(): TweenLite {
        return TweenLite.fromTo(this.light, 0.5, { className: "lighting" }, { className: "lighting active" });
    }

    public inactivate(): TweenLite {
        return TweenLite.fromTo(this.light, 0.5, { className: "lighting active" }, { className: "lighting" });
    }
     
     
	public onClick() {
        
		if (this.isAnimating) {
			return;
		}

		let timeline = new TimelineLite()
			.to(this.light, 0.15, { scale: 0.8 }, "start")
			.to(this.light, 2, { scale: 1, ease: Elastic.easeOut.config(1.1, 0.4) })
			.to(this.light, 1, {  y: -50 })
        .to(this.light, 1, {  y: 0 });
        //.to(this.light, 1, { ease: Bounce.easeOut, scale: 1 });
        //to(graph, 2.5, { ease: Bounce.easeOut, y: -500 });

		timeline.add(this.toggle(), "start");
        
		this.toggled.emit(timeline);
        
	}
     
     
	public reset() {
		this.setState();
	}

	public toggle() {
		this._animating = true;
		let animation = this.isActive ? this.inactivate() : this.activate();
		this._active = !this.isActive;

		return new TimelineLite().add(animation).add(() =>
			this.ngZone.run(() => {
				this._animating = false;
			})
		);
	}

	private setState() {
		this._active = Math.random() > 0.5;
		if (this.isActive) {
			TweenLite.set(this.light, { className: "lighting active" });
		} else {
			TweenLite.set(this.light, { className: "lighting" });
		}
	}
     
     
   
}
