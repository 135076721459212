import { Injectable } from '@angular/core';
//import { RouterStateSnapshot, ActivatedRouteSnapshot, CanDeactivate } from '@angular/router';


import { Observable, of} from 'rxjs';
//import { of as observableOf } from 'rxjs/observable/of'

@Injectable()
export class DialogService {
  confirm(message?: string): Observable<boolean> {
    const confirmation = window.confirm(message || 'Are you sure?');

    return of(confirmation);
    //  return Observable.of(confirmation);
  };
} 
