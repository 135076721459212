import { Component, OnInit } from '@angular/core';

import { DialogConfig } from '../chatbot/dialog-config'

import { DialogRef } from '../chatbot/dialog-ref'

@Component({
  selector: 'app-example',
  templateUrl: './example.component.html',
  styleUrls: ['./example.component.sass']
})
export class ExampleComponent implements OnInit {

  constructor(public config: DialogConfig, public dialog: DialogRef) {}

  onClose() {
    this.dialog.close('some value')
  }
    
  ngOnInit() {
  }
    
}
