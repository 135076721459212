import { Component, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-chat-user-icon',
  templateUrl: './chat-user-icon.component.html',
  styleUrls: ['./chat-user-icon.component.sass']
})
export class ChatUserIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}


/**

export class MyDirective implements OnInit {
  constructor(private elementRef: ElementRef, private renderer: Renderer2, @Inject(DOCUMENT) private document) { }

  ngOnInit() {
    const child = document.createElement('div');
    this.renderer.appendChild(this.elementRef.nativeElement, child);
  }
}

**/