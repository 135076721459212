//import { Component, OnInit } from '@angular/core';
import { Component, ElementRef, EventEmitter, NgZone, OnInit, Output, Input, ViewChild } from "@angular/core";
import { Elastic, TimelineLite, TweenLite, Power2 } from "gsap";

import { Router, RouterModule } from '@angular/router';



@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.sass']
})
export class NavbarComponent implements OnInit {

    private get elem(): HTMLElement {
        return this._elem.nativeElement;
    }
  constructor(private _elem: ElementRef, private ngZone: NgZone, private router:Router) {}

    private _navbarAnimate: boolean;
    
  ngOnInit() {
      this._navbarAnimate = false;
  }
    
    //r={navbar:{ menu:{
    /**
               
         **/   
   //return (this.flashAnimation = TweenMax.to(this.elem.querySelector("div"), 0.25, { className: "+=flash" })
    //.repeat(-1)
    //.yoyo(true));
    
     public open(){
                         TweenLite.fromTo(this.elem.querySelector("nav"), 0.2, { className: "cb-navbar" }, { className: "cb-navbar -open" });
                
         
        var t=new TimelineLite({paused:!0});
        var a=this.elem.querySelector(".cb-navbar-backdrop");
        var n=this.elem.querySelector(".cb-navbar-menu-fill");
        var r=this.elem.querySelectorAll(".cb-navbar-menu-nav-item");
        var i=this.elem.querySelectorAll(".cb-navbar-menu-nav-item span");
        var s=this.elem.querySelectorAll(".cb-social-item");
        var l=this.elem.querySelectorAll(".cb-social-item-inner");

       return 
            t.fromTo(a,.3,{opacity:1},{opacity:0},0),
            t.to(n,.6,{scaleY:3,ease:Power2.easeInOut},0),
            t.staggerFromTo(r,.4,{x:"0%",opacity:1},{x:"100%",opacity:0,clearProps:"x"},.1,0),
            t.staggerFromTo(i,.4,{x:"0%"},{x:"-100%",clearProps:"x"},.1,0),
            t.staggerFromTo(s,.3,{y:"0%"},{y:"170%"},.05,.1),
            t.staggerFromTo(l,.3,{y:"0%"},{y:"-120%"},.05,.1),t
                 
         /**
        return 
            t.fromTo(a,.3,{opacity:1},{opacity:0},0),
            t.to(n,.6,{scaleY:3,ease:Power2.easeInOut},0),
            t.staggerFromTo(r,.4,{x:"0%",opacity:1},{x:"100%",opacity:0,clearProps:"x"},.1,0),
            t.staggerFromTo(i,.4,{x:"0%"},{x:"-100%",clearProps:"x"},.1,0),
            t.staggerFromTo(s,.3,{y:"0%"},{y:"170%"},.05,.1),
            t.staggerFromTo(l,.3,{y:"0%"},{y:"-120%"},.05,.1),t
            **/
     }
    
    
            public show(){
                
                
                // -open
                //TweenLite.fromTo(this.elem.querySelector("nav"), 0.2, { className: "cb-navbar" }, { className: "cb-navbar -open" });
                
                
                    //isHandheldSize=$("html").hasClass("mobile")||$("html").hasClass("tablet");
                    var isHandheldSize = false;
                    var t=new TimelineLite({paused:true});
                
                    var r=this.elem.querySelector(".cb-navbar-backdrop");
                    var i=this.elem.querySelector(".cb-navbar-menu-fill");
                    var s=this.elem.querySelectorAll(".cb-navbar-menu-nav-item");
                    var l=this.elem.querySelectorAll(".cb-navbar-menu-nav-item span");
                    var c=this.elem.querySelectorAll(".cb-social-item");
                    var f=this.elem.querySelectorAll(".cb-social-item-inner");

                
                //console.log(r);
                //console.log(s[0]);
                return t.fromTo(r,.3,{opacity:0},{opacity:1},0),
                t.fromTo(i,1,{scaleY:0},{scaleY:1,ease:Elastic.easeOut.config(1.1,.9)},0),
                t.staggerFromTo(s,.4,{y:"150%"},{y:"0%",opacity:1},.1,.1),
                t.staggerFromTo(l,.4,{y:"-100%"},{y:"0%"},.1,.1),
                t.staggerFromTo(c,.3,{y:"120%"},{y:"0%"},.07,.2),
                t.staggerFromTo(f,.3,{y:"-120%"},{y:"0%"},.07,.2);
/**
                return t.fromTo(r,.3,{opacity:0},{opacity:1},0),
                    //a("xs")?t.fromTo(i,1,{scaleY:0},{scaleY:1,ease:Elastic.easeOut.config(1.1,.9)},0):
                    t.fromTo(i,.4,{scaleY:0},{scaleY:1,ease:Power2.easeInOut},0),
                    isHandheldSize?(t.staggerFromTo(s,.4,{opacity:0},{opacity:1},.08,.1),t.staggerFromTo(c,.6,{opacity:0},{opacity:1},.03,.3)):(t.staggerFromTo(s,.4,{y:"150%"},{y:"0%",opacity:1},.1,.1),t.staggerFromTo(l,.4,{y:"-100%"},{y:"0%"},.1,.1),t.staggerFromTo(c,.3,{y:"120%"},{y:"0%"},.07,.2),t.staggerFromTo(f,.3,{y:"-120%"},{y:"0%"},.07,.2)),
                    t;
                **/
                }
            
    

            /**
            hide(e){
                
                isHandheldSize=$("html").hasClass("mobile")||$("html").hasClass("tablet");
                
                var t=new TimelineLite({paused:!0}),
                    o=e,r=o.find(".cb-navbar-backdrop"),i=o.find(".cb-navbar-menu-fill"),s=o.find(".cb-navbar-menu-nav-item"),l=o.find(".cb-navbar-menu-nav-item span"),
                    c=o.find(".cb-social-item"),f=c.find(".cb-social-item-inner");
                
                return isHandheldSize?(t.staggerFromTo(s,.2,{opacity:1},{opacity:0},.07,0),t.staggerFromTo(c,.2,{opacity:1},{opacity:0},.05,0)):
                (t.staggerFromTo(s,.2,{y:"0%"},{y:"-100%",opacity:0},.07,0),t.staggerFromTo(l,.2,{y:"0%"},{y:"100%"},.07,0),t.staggerFromTo(c,.2,{y:"0%"},{y:a("sm")?"170%":"120%"},.05,0),t.staggerFromTo(f,.2,{y:"0%"},{y:"-120%"},.05,0)),
                    
                    t.fromTo(r,.6,{opacity:1},{opacity:0},.2),t.fromTo(i,.6,{scaleY:1},{scaleY:0,ease:Power2.easeInOut},.25),t
            }
                    
                    
            open(e){
                  var t=new TimelineLite({paused:!0}),o=e,a=o.find(".cb-navbar-backdrop"),n=o.find(".cb-navbar-menu-fill"),r=o.find(".cb-navbar-menu-nav-item"),i=o.find(".cb-navbar-menu-nav-item span"),s=o.find(".cb-social-item"),l=s.find(".cb-social-item-inner");
                return 
                    t.fromTo(a,.3,{opacity:1},{opacity:0},0),
                    t.to(n,.6,{scaleY:3,ease:Power2.easeInOut},0),
                    t.staggerFromTo(r,.4,{x:"0%",opacity:1},{x:"100%",opacity:0,clearProps:"x"},.1,0),
                    t.staggerFromTo(i,.4,{x:"0%"},{x:"-100%",clearProps:"x"},.1,0),
                    t.staggerFromTo(s,.3,{y:"0%"},{y:"170%"},.05,.1),
                    t.staggerFromTo(l,.3,{y:"0%"},{y:"-120%"},.05,.1),t}

    **/            
    
    public hide(){

           var isHandheldSize = false;
        var t=new TimelineLite({paused:true});

        var r=this.elem.querySelector(".cb-navbar-backdrop");
        var i=this.elem.querySelector(".cb-navbar-menu-fill");
        var s=this.elem.querySelectorAll(".cb-navbar-menu-nav-item");
        var l=this.elem.querySelectorAll(".cb-navbar-menu-nav-item span");
        var c=this.elem.querySelectorAll(".cb-social-item");
        var f=this.elem.querySelectorAll(".cb-social-item-inner");

        return isHandheldSize?
            (t.staggerFromTo(s,.2,{opacity:1},{opacity:0},.07,0),t.staggerFromTo(c,.2,{opacity:1},{opacity:0},.05,0)):
        (t.staggerFromTo(s,.2,{y:"0%"},{y:"-100%",opacity:0},.07,0),
         t.staggerFromTo(l,.2,{y:"0%"},{y:"100%"},.07,0),
         t.staggerFromTo(c,.2,{y:"0%"},
                        // {y:a("sm")?"170%":"120%"},.05,0),
                         {y:"120%"},.05,0),
         t.staggerFromTo(f,.2,{y:"0%"},{y:"-120%"},.05,0)),
            
            t.fromTo(r,.6,{opacity:1},{opacity:0},.2),t.fromTo(i,.6,{scaleY:1},{scaleY:0,ease:Power2.easeInOut},.25),t;
    }
    
    
	public navbarClose(){
        if(this._navbarAnimate)
            return false;
        
        let navbar = this.elem.querySelector("nav"); 
        let navbarToggle = this.elem.querySelector(".cb-navbar-toggle"); 
        
        if(navbarToggle.classList.contains('-active'))
        {
            navbarToggle.classList.remove('-active'); 
        }
        this._navbarAnimate=true;
        var t = this.hide();
                         
                                 
        
        t.eventCallback("onComplete",
            ()=>this.ngZone.run(() => {
                if(navbar.classList.contains('-open'))
                {
                    navbar.classList.remove('-open'); 

                }
                this._navbarAnimate = false;
            })                    
        );
        //e.illustration&&e.illustration.play()})
        
        t.play();
    }
    
    
    public navigateto(destination){
        this.toggleClick();
        this.router.navigate([destination]);
        
    }
    
    public toggleClick(){
        let navbarToggle = this.elem.querySelector(".cb-navbar-toggle"); 
       
        if(!navbarToggle.classList.contains('-active'))
        {
            this.navbarOpen();
        }else{
            this.navbarClose();
        }
        
    }
                   
    public navbarOpen(){
        if(this._navbarAnimate)
            return false;
        
        let navbar = this.elem.querySelector("nav"); 
        let navbarToggle = this.elem.querySelector(".cb-navbar-toggle"); 
        
        if(!navbar.classList.contains('-open'))
        {
            navbar.classList.add('-open'); 
        }
        if(!navbarToggle.classList.contains('-active'))
        {
            navbarToggle.classList.add('-active'); 
        }
        
        
        var t=this.show();
        t.eventCallback("onComplete",
        () =>
			this.ngZone.run(() => {
				this._navbarAnimate = false;
			}
        ));
                        
        t.play();
        /**
        //e.illustration&&e.illustration.pause(),
        e.navbar.addClass("-open");
        e.navbarToggle.addClass("-active");
        navbarAnimate=true;
        
        
        var t=r.default.navbar.menu.show(e.navbar);
        t.eventCallback("onComplete",function(){
            e.navbarAnimate=!1
        }),
        t.play()
        
        **/
        
        
        
    }
        /**
        ,e.navbarClose=function(){if(e.navbarAnimate)return!1;e.navbarToggle.removeClass("-active"),e.navbarAnimate=!0;var t=r.default.navbar.menu.hide(e.navbar);t.eventCallback("onComplete",function(){e.navbar.removeClass("-open"),e.navbarAnimate=!1,e.illustration&&e.illustration.play()}),t.play()},e.navbarGo=function(t){if(e.navbarTranslate)return!1;e.navbar.removeClass("-back"),e.navbarToggle.removeClass("-active"),e.navbarTranslate=!0;var o=r.default.navbar.menu.open(e.navbar);o.eventCallback("onComplete",function(){e.view.html(""),e.navbar.removeClass("-open"),setTimeout(function(){e.navbarTranslate=!1,e.goTo({url:t,triggerLeave:!1})},100)}),o.play()},e.navbarBack.on("click",function(t){e.req.prevCtrl&&(t.preventDefault(),t.stopPropagation(),window.history.back())}),e.navbarToggle.on("click",function(){e.navbar.hasClass("-open")?e.navbarClose():e.navbarOpen()})
    **/
    
    

}
