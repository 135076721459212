import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'; // <-- NgModel lives here

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeroesComponent } from './heroes/heroes.component';
import { HeroDetailComponent } from './hero-detail/hero-detail.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MessagesComponent } from './messages/messages.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { NavbarComponent } from './navbar/navbar.component';
import { IntroComponent } from './intro/intro.component';

import { InViewportModule } from 'ng-in-viewport';

import { DialogService } from './dialog.service'


import { RouterModule } from '@angular/router';
import { FeatureComponent } from './feature/feature.component';
import { ProjectBoxComponent } from './project-box/project-box.component';
import { ServiceDigitalComponent } from './service-digital/service-digital.component';
import { ServiceMobileComponent } from './service-mobile/service-mobile.component';
import { ServiceBrandingComponent } from './service-branding/service-branding.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { CareerComponent, DialogOverviewExampleDialog } from './career/career.component';


import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { environment } from 'src/environments/environment';



import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {MatButtonModule, MatCheckboxModule, MatInputModule,
       MatDialogModule, MatProgressSpinnerModule} from '@angular/material';

import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import { ChatComponent } from './chat/chat.component';


import { ChatbotModule } from './chatbot/chatbot.module';


import { FontAwesomeModule, FaIconComponent } from '@fortawesome/angular-fontawesome';
import { ChatUserIconComponent } from './chat-user-icon/chat-user-icon.component';
import { ChatMessageComponent } from './chat-message/chat-message.component';
import { ExampleComponent } from './example/example.component';
import { DialogInjectorComponent } from './app/chatbot/dialog-injector/dialog-injector.component';





@NgModule({
  declarations: [
    AppComponent,
    HeroesComponent,
    HeroDetailComponent,
    DashboardComponent,
    MessagesComponent,
    SidemenuComponent,
    NavbarComponent,
    IntroComponent,
    FeatureComponent,
    ProjectBoxComponent,
    ServiceDigitalComponent,
    ServiceMobileComponent,
    ServiceBrandingComponent,
    AboutComponent,
    ContactComponent,
    CareerComponent,
    DialogOverviewExampleDialog,
    ChatComponent,
    ChatUserIconComponent,
    ChatMessageComponent,
    ExampleComponent,
    DialogInjectorComponent
      
  ],
    
  entryComponents: [DialogOverviewExampleDialog, FaIconComponent, ChatMessageComponent, ExampleComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    RouterModule,
    InViewportModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    BrowserAnimationsModule,
      
    MatButtonModule, MatCheckboxModule, MatInputModule,
    MatDialogModule,
    MatTableModule, MatPaginatorModule, MatSortModule,
    MatProgressSpinnerModule,
      
    FontAwesomeModule,
      
    ChatbotModule
  ],
    providers: [
       DialogService
    ],
  //providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
