export class MessageList {
    title: string;
    description: string;
    type: MessageListType;
    items: Array<MessageListItem>;
}

export class MessageListItem {
    title: string;
    description: string;
}

export const enum MessageListType {
  BUTTON = 1, CARD = 2
}

