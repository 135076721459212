import { Component, OnInit, OnChanges, ViewChild, ViewContainerRef, ComponentRef, ComponentFactoryResolver, SimpleChanges, ElementRef, 
        Input, EventEmitter, Output  } from '@angular/core';

import { FaIconComponent } from '@fortawesome/angular-fontawesome';

import { faStar as farStar, faUser as farUser } from '@fortawesome/free-regular-svg-icons';

import { faRobot as fasRobot } from '@fortawesome/free-solid-svg-icons';

import { MessageList, MessageListItem, MessageListType } from '../chatbot/message-list'

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.sass']
})
export class ChatMessageComponent implements OnInit, OnChanges {

  @Input() message: string;
  @Input() mood: string;
  @Input() isReceived: boolean;
    
  @ViewChild('vcRef', { read: ViewContainerRef })
  private vcRef: ViewContainerRef;
   
  @ViewChild('mainMsgRef') 
  private mainBody: ElementRef;
   
   /**
    dmxintros: Array<{url: string, name: string}> = [
        {url: 'services/webs', name: 'Websites'},
        {url: 'services/apps', name: 'Apps'},
       {url: 'services/solutions', name: 'Solutions'}
    ];
   **/
   
  @Input() list: MessageList;
   
  @Output() clickMessageOption: EventEmitter<any> = new EventEmitter();
  public isActive: boolean = true
   
  private componentInstance: ComponentRef<FaIconComponent> = null;
  private get elem(): HTMLElement {
      return this.el.nativeElement;
  }

           
  constructor(private el: ElementRef, private cfr: ComponentFactoryResolver) { }

   
   handleClick(e, itemClicked: MessageListItem){
       
       if(this.isActive){
           this.clickMessageOption.next(itemClicked)
           e.preventDefault()
       }
      
   }
  ngOnInit() {
      /**
      this.list.items = [{title:'Cardiology', description:'Cardiology'},
                         {title:'Oncology', description:'Oncology'},
                         {title:'General Surgery', description:'General Surgery'},
                         {title:'Neurology', description:'Neurology'}]; **/
//       <div id="content" class="content invisible"><h1 id="contentbody" class="text invisible">
      //<span class="fullname" [textContent]="message"></span></h1></div>

      /**
      let message = document.createElement('div')
      message.id = 'content'
      message.classList.add("content")
      message.classList.add("invisible")
      
      let messageH1 = document.createElement('h1')
      messageH1.id = 'contentbody'
      messageH1.classList.add("text")
      messageH1.classList.add("invisible")
      messageH1.innerText = this.message;
      
      message.appendChild(messageH1)
      
      
      let _main = this.elem.querySelector('#messagemain');
      
      
      if(this.isReceived){
            _main.appendChild(message);
      }else{
            let sibling = _main.children[0]
            _main.insertBefore(message,sibling);
      }
      **/
      
      let mainBodyDOM = this.mainBody.nativeElement as HTMLElement
      mainBodyDOM.classList
      
     // let _main = this.elem.querySelector('#messagemain');
      
      let contentClassNames = 'content invisible'
      if(this.isReceived){
           this.addIcon(fasRobot)
           
           //_main.classList.add('received')
            contentClassNames += this.mood
           
            if(!mainBodyDOM.classList.contains(this.mood)){
               mainBodyDOM.classList.add(this.mood)
            }
      }else{
           this.addIcon(farUser)
           //_main.classList.add('sent')
          
      }
           
      
      
                    
      
      let _content = this.elem.querySelector('#content');
      let _contentbody = this.elem.querySelector('#contentbody');
      let _profileIcon = this.elem.querySelector('#profileicon');
           
      
       
      let className = 'invisible'
     
                   


      setTimeout(() => {
          if(_profileIcon.classList.contains(className))
            _profileIcon.classList.remove(className)

             setTimeout(() => {
                  if(_content.classList.contains(className))
                    _content.classList.remove(className)

                   if(_contentbody.classList.contains(className))
                    _contentbody.classList.remove(className)
            }, 250);
      }, 250);

      
       
        
        
  }

  addIcon(icon: any) {
        const factory = this.cfr.resolveComponentFactory(FaIconComponent);
        this.componentInstance = this.vcRef.createComponent(factory);
        this.componentInstance.instance.iconProp = icon;
        this.componentInstance.instance.size = 'lg';
        this.componentInstance.instance.ngOnChanges({});
      
  }

  ngOnChanges(changes: SimpleChanges) {
  }
   
    /**
  createChatMessage = (text, isReceived) => {
  let message = document.createElement('div'),
      profileIcon = document.createElement('div'),
      icon = document.createElement('i'),
      content = document.createElement('div'),
      contentText = document.createElement('h1')
  
  this.addClass(content, 'content')
  this.addClass(content, 'invisible')
  this.addClass(contentText, 'text')
  this.addClass(contentText, 'invisible')
  this.appendContentText(contentText, text)
  content.appendChild(contentText)
  
  this.addClass(profileIcon, 'profile-icon')
  this.addClass(profileIcon, 'invisible')
  profileIcon.appendChild(icon)
  
  this.addClass(message, 'message')
  this.addClass(message, isReceived ? 'received' : 'sent')
  
  if(isReceived){
    this.addClass(icon, 'fab')
    this.addClass(icon, 'fa-cloudsmith')
    this.addClass(message, this.STATE.currentMood)
    message.appendChild(profileIcon)
    message.appendChild(content)
  }
  else{
    this.addClass(icon, 'far')
    this.addClass(icon, 'fa-user')
    
    message.appendChild(content)
    message.appendChild(profileIcon)
  }
  
  return message
}
**/
   
}
