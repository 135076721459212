import { Component, OnInit, ViewChild,ElementRef } from '@angular/core';
import { Elastic, TimelineLite, TweenLite, Bounce } from "gsap";



import { IntroComponent }  from './intro/intro.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass', './aos.sass']
})
export class AppComponent implements OnInit {
  title = 'Tour of Heroes';
    
    @ViewChild("logo")
	private _logo: ElementRef;
	private get logo(): HTMLElement {
        
		return this._logo.nativeElement;
	}
      ngOnInit() {}
   

    
    
    ngOnInit_backup(){
        let tl = new TimelineLite();
        tl.add( TweenLite.to(this.logo, 5, {left:100}) );
        tl.add("myLabel", "-=5");
        tl.add( TweenLite.to(this.logo, 5, {opacity:1}), "myLabel" );
        tl.play();
       
    }
    
    
     public changeOfRoutes(){
        console.log("from home->");
     }
    
    
    
}
