import { Component, Type, OnDestroy, AfterViewInit, ViewChild, ComponentRef, ComponentFactoryResolver, ChangeDetectorRef } from '@angular/core'
import { InsertionDirective } from '../insertion.directive'

import { Subject } from 'rxjs';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.sass']
})
export class DialogComponent implements AfterViewInit, OnDestroy {
 
  //need a component wide ref because need to destroy it.
  private componentRef: ComponentRef<any>
      
  public childComponentType: Type<any>
      
  //private readonly _onClose = new Subject<any>()
  //public onClose = this._onClose.asObservable()

  // add this:
  @ViewChild(InsertionDirective)
  insertionPoint: InsertionDirective

  // and this:
  constructor(private componentFactoryResolver: ComponentFactoryResolver,  private cd: ChangeDetectorRef) {}
    

  
  
  ngAfterViewInit() {
        this.loadChildComponent(this.childComponentType);
        this.cd.detectChanges();
      
  }

     ngOnDestroy() {
      if (this.componentRef) {
        this.componentRef.destroy();
      }
    }

  onOverlayClicked(evt: MouseEvent) {
    // close the dialog
  }

  onDialogClicked(evt: MouseEvent) {
    evt.stopPropagation()
  }
    
    loadChildComponent(componentType: Type<any>) {
        let componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);

        let viewContainerRef = this.insertionPoint.viewContainerRef;
        viewContainerRef.clear();

        this.componentRef = viewContainerRef.createComponent(componentFactory);
    }

    
}