import { Component, OnInit, Renderer2 } from '@angular/core';

import { Elastic, TimelineLite, TweenLite, Power2, Linear, ScrollToPlugin } from "gsap/all";

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.sass']
})
export class AboutComponent implements OnInit {
    constructor(private renderer: Renderer2) {}
    ngOnInit() {
    }
    public onIntersection({ target, visible }: { target: Element; visible: boolean }): void {
        if(visible){
             if(!target.classList.contains('aos-animate'))
                this.renderer.addClass(target, 'aos-animate');
        }else{
            if(target.classList.contains('aos-animate'))
                this.renderer.removeClass(target, 'aos-animate');
        }
    }

/**
    public clickFilterItem(item,idx){
        var t=item;
        console.log(this.projectBoxBody);
        var list1 = this.projectBoxBody.querySelectorAll(".cb-projectbox-area");
        var n = list1[idx];
        var r=this.projectBoxBody.style.paddingTop+ (n.classList.contains("aos-animate")?10:110);
        TweenLite.to(window,1, {scrollTo:{y:n,offsetY:r},ease:Power2.easeInOut,autoKill:false});
    }
**/

    
    
    

    

}
